import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";

const FeedbackLineChart = () => {
  const [feedbackData, setFeedbackData] = useState([]);
  const [filter, setFilter] = useState("week");
  const [year, setYear] = useState("");
  const [chartOptions, setChartOptions] = useState({
    options: {
      chart: {
        id: "feedback-chart",
        type: "line",
        toolbar: { show: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Period" },
        labels: {
          style: {
            fontSize: '12px',
            colors: [],
            margin: 10,
          
          },
         
          rotate: 0,
          
        },
      },
    
      yaxis: {
        title: { text: "Feedback Counts" },
      },
      dataLabels: { enabled: false },
      stroke: { curve: "smooth", width: 2 },
      colors: ["#28a745", "#FF4560", "#FEB019"],
      legend: { position: "bottom" },
    },
    series: [],
  });

  useEffect(() => {
    const fetchFeedbackData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/feedback/list-categorised/filtered/?filter=${filter}`,
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = response.data;
        const periods = data.map((item) => item.period);
        const positive = data.map((item) => item.positive_feedback_count);
        const negative = data.map((item) => item.negative_feedback_count);
        const neutral = data.map((item) => item.neutral_feedback_count);

        // Extract the year from the first period
        if (periods.length > 0 && !year) {
          const extractedYear = periods[0].split(" ").pop();
          setYear(extractedYear);
        }


       

        // Remove the year from the periods and format the dates
        const categoriesWithoutYear = periods.map(period => {
          const parts = period.split(" ");
          if (filter === "week") {
            return `${parts[1]} ${parts[2]}`;
          }
          return parts.slice(0, parts.length - 1).join(" ");
        });
        
        setChartOptions((prev) => ({
          ...prev,
          options: { ...prev.options, xaxis: { ...prev.options.xaxis, categories: categoriesWithoutYear } },
          series: [
            { name: "Positive Feedback", data: positive },
            { name: "Negative Feedback", data: negative },
            { name: "Neutral Feedback", data: neutral },
          ],
        }));

        setFeedbackData(data);
      } catch (error) {
        console.error("Error fetching feedback data:", error);
      }
    };

    fetchFeedbackData();
  }, [filter, year]);

  return (
    <div className="w-full">
     <div className="px-6 py-4 border-b flex justify-between">
     <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200 flex items-center gap-2">Categorised feedback</h2>
     <div className="  ">
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="px-2 text-[12px] py-1 font-medium bg-white  text-[#454861] border border-[#E0E0E0] rounded-[4px]"
            >
              {["day", "week", "month", "year"].map((timeFilter) => (
                <option key={timeFilter} value={timeFilter}>
                  {timeFilter.charAt(0).toUpperCase() + timeFilter.slice(1)}
                </option>
              ))}
            </select>
          </div>
     </div>
     <div className="p-6 w-full">
  
      <div className="flex items-center space-x-2 justify-end">
        <span className="text-[#6F707E] text-[12px]">Year:</span>
        <span className="text-[14px] font-semibold text-[#454861]">{year}</span>
      </div>
      

    

      <Chart
        options={chartOptions.options}
        series={chartOptions.series}
        type="line"
        height="400"
      />
     </div>
    </div>
  );
};

export default FeedbackLineChart;
