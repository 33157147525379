import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RewardAmountUpdater = () => {
  const [rewardAmount, setRewardAmount] = useState("");
  const [currentRewardAmount, setCurrentRewardAmount] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currency, setCurrency] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState("");

  const profileEndpoint = "https://zola.technology/api/company/profile/";
  const updateEndpoint = "https://zola.technology/api/company/update/";

  const fetchProfileData = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(profileEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch profile data.");
      }

      const data = await response.json();
      setCurrentRewardAmount(data.reward_amount_per_score);
      setRewardAmount(data.reward_amount_per_score);
      setPhoneNumber(data.phone_number);

      // Determine the currency based on the phone number
      if (data.phone_number.startsWith("254")) {
        setCurrency("KES");
      } else {
        setCurrency("USD");
      }
    } catch (error) {
      setError("Unable to load profile data. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const updateRewardAmount = async () => {
    if (!rewardAmount || isNaN(rewardAmount)) {
      toast.error("Please enter a valid reward amount.");
      return;
    }

    setIsSaving(true);
    setError("");

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(updateEndpoint, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ reward_amount_per_score: rewardAmount }),
      });

      if (!response.ok) {
        throw new Error("Failed to update reward amount.");
      }

      toast.success("Reward amount updated successfully!");
      setCurrentRewardAmount(rewardAmount);
    } catch (error) {
      setError("An error occurred while updating the reward amount. Please try again.");
      toast.error("Failed to update reward amount. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  return (
    <div className="w-full mx-auto mt-12  p-8">
      <h2 className="text-xl font-semibold text-gray-800 mb-6 text-center">Reward Amount Manager</h2>
      <ToastContainer />
      {isLoading ? (
        <p className="text-gray-500 text-center">Loading profile data...</p>
      ) : error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : (
        <>
          <div className="text-center mb-4">
            <p className="text-gray-600">
              <strong>Current Reward Amount:</strong>{" "}
              <span className="text-blue-600 font-bold">
                {currency} {Number(currentRewardAmount).toFixed(2)}
              </span>
            </p>
          </div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Update Reward Amount ({currency}):
          </label>
          <input
            type="number"
            value={rewardAmount}
            onChange={(e) => setRewardAmount(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="Enter reward amount"
          />
          <button
            aria-label="Update Reward Amount"
            aria-disabled={isSaving}
            onClick={updateRewardAmount}
            disabled={isSaving}
            className={`mt-4 w-full px-4 py-2 font-medium text-white rounded-md ${
              isSaving ? "bg-blue-300 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700"
            } transition-all duration-300`}
          >
            {isSaving ? "Saving..." : "Update Reward"}
          </button>
        </>
      )}
    </div>
  );
};

export default RewardAmountUpdater;
