import { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { IoFunnelOutline, IoFilterSharp } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import useReviewsData from "../custom-hook/useReviewsData";

const UserModal = ({ userInfo, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black/50 dark:bg-white/50 flex justify-center items-center z-50">
      <div className="bg-gray-800 text-white dark:bg-white dark:text-gray-800 p-6 rounded-lg shadow-lg max-w-sm w-full max-h-full overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4">User Info</h2>
        <div className="flex items-center gap-4 mb-4">
          <img
            src={userInfo.userImage}
            alt={userInfo.userName}
            className="w-16 h-16 rounded-full object-cover"
          />
          <div>
            <p className="font-bold">Name:</p>
            <p>{userInfo.userName}</p>
            <p className="font-bold mt-2">Rating:</p>
            <p>{userInfo.rating}</p>
          </div>
        </div>
        <p className="font-bold mt-2">Review:</p>
        <p>{userInfo.review}</p>
        <button
          aria-label="Close Modal"
          onClick={onClose}
          className="mt-4 w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 transition-colors"
        >
          Close
        </button>
      </div>
    </div>
  );
};

UserModal.propTypes = {
  userInfo: PropTypes.shape({
    userName: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    review: PropTypes.string.isRequired,
    userImage: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

const ReviewsSection = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("recent");
  const [filterBy, setFilterBy] = useState("all");
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUserModal, setShowUserModal] = useState(false);
  const { reviews, loading } = useReviewsData();

  useEffect(() => {
    document.body.style.overflow = showUserModal ? "hidden" : "";
    return () => {
      document.body.style.overflow = "";
    };
  }, [showUserModal]);

  const filteredAndSortedReviews = useMemo(() => {
    let filteredReviews = Array.isArray(reviews) ? reviews : [];

    if (searchTerm) {
      filteredReviews = filteredReviews.filter((review) =>
        review.userName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (sortBy === "recent") {
      return filteredReviews.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
    } else if (sortBy === "rating") {
      return filteredReviews.sort((a, b) => b.rating - a.rating);
    }
    return filteredReviews;
  }, [reviews, searchTerm, sortBy, filterBy]);

  const handleManageClick = (userInfo) => {
    setSelectedUser(userInfo);
    setShowUserModal(true);
  };

  const handleCloseUserModal = () => {
    setShowUserModal(false);
    setSelectedUser(null);
  };

  const handleSortChange = (value) => {
    setSortBy(value);
    setShowSortOptions(false);
  };

  const handleFilterChange = (value) => {
    setFilterBy(value);
    setShowFilterOptions(false);
  };

  if (loading) {
    return (
      <section className="flex justify-center items-center h-full">
        <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
      </section>
    );
  }

  return (
    <section className="p-6 bg-white text-black dark:bg-gray-800 dark:text-white rounded-lg shadow-lg">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <div className="flex items-center bg-gray-200 border border-gray-300 dark:bg-gray-700 dark:border-gray-600 p-2 rounded-full w-full max-w-sm mb-4 sm:mb-0 shadow-md">
          <FaSearch className="text-blue-500 mr-2" />
          <input
            type="text"
            placeholder="Search..."
            className="bg-transparent outline-none flex-grow text-black dark:text-white"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="ml-2 text-gray-600 dark:text-gray-400 text-xs hidden md:block">
            ⌘F
          </div>
        </div>
        <div className="relative flex items-center justify-center gap-4">
          <button
            aria-label="Sort Options"
            onClick={() => setShowSortOptions(!showSortOptions)}
            className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600"
          >
            <IoFilterSharp className="text-gray-700 dark:text-gray-300" />
            <span className="ml-2">Sort By</span>
            <IoFunnelOutline
              className={`ml-2 transition-transform ${
                showSortOptions ? "rotate-180" : ""
              }`}
            />
          </button>
          {showSortOptions && (
            <div className="absolute top-full left-0 mt-2 w-full bg-gray-200 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg">
              <div
                onClick={() => handleSortChange("recent")}
                className="p-2 cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-600"
              >
                Recent
              </div>
              <div
                onClick={() => handleSortChange("rating")}
                className="p-2 cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-600"
              >
                Rating
              </div>
            </div>
          )}
          <button
            aria-label="Filter Options"
            onClick={() => setShowFilterOptions(!showFilterOptions)}
            className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600"
          >
            <IoFunnelOutline className="text-gray-700 dark:text-gray-300" />
            <span className="ml-2">Filter</span>
            <IoFunnelOutline
              className={`ml-2 transition-transform ${
                showFilterOptions ? "rotate-180" : ""
              }`}
            />
          </button>
          {showFilterOptions && (
            <div className="absolute top-full left-0 mt-2 w-full bg-gray-200 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg">
              <div
                onClick={() => handleFilterChange("all")}
                className="p-2 cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-600"
              >
                All
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="relative grid grid-cols-1 sm:grid-cols-2 gap-4 min-h-[200px]">
        {filteredAndSortedReviews.length === 0 ? (
          <div className="absolute inset-0 flex items-center justify-center text-gray-600 dark:text-gray-400">
            No reviews found.
          </div>
        ) : (
          filteredAndSortedReviews.map((review, index) => (
            <div
              className="bg-gray-100 dark:bg-gray-700 p-4 border border-gray-300 dark:border-gray-600 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow"
              key={index}
              onClick={() => handleManageClick(review)}
            >
              <div className="flex items-center gap-4 mb-4">
                <img
                  src={review.userImage}
                  alt={review.userName}
                  className="w-12 h-12 rounded-full object-cover"
                />
                <div>
                  <p className="font-semibold">{review.userName}</p>
                  <div className="text-yellow-500 dark:text-yellow-400">
                    {"★".repeat(Math.floor(review.rating))}
                  </div>
                </div>
              </div>
              <p>{review.review}</p>
            </div>
          ))
        )}
      </div>
      {showUserModal && selectedUser && (
        <UserModal userInfo={selectedUser} onClose={handleCloseUserModal} />
      )}
    </section>
  );
};

export default ReviewsSection;
