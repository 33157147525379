import { useEffect, useState } from "react";
import { LuMessagesSquare } from "react-icons/lu";
import { BiSolidLike, BiSolidDislike } from "react-icons/bi";
import { FaHandFist } from "react-icons/fa6";
import {
  FaUser,
  FaCalendarAlt,
  FaCheckCircle,
  FaTimesCircle,
  FaPhoneAlt,
  FaStar,
} from "react-icons/fa";
import { MdFeedback, MdOutlineLink, MdClose } from "react-icons/md";
import { GoArrowLeft } from "react-icons/go";
import { PiChatsFill } from "react-icons/pi";
import { PiThumbsUpFill } from "react-icons/pi";
import { PiThumbsDownFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";

function Feedback() {
  const [activeTab, setActiveTab] = useState("responded");

  const [filteredFeedback1, setFilteredFeedback1] = useState([]);
  const [categorizedFeedback, setCategorizedFeedback] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [ratingData, setRatingData] = useState({
    rating_of_1_count: 0,
    rating_of_2_count: 0,
    rating_of_3_count: 0,
    rating_of_4_count: 0,
    rating_of_5_count: 0,
  });

  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchData = async (url, setData) => {
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        console.log(data);
        setData(data);
      } catch (err) {
        setError(err.message);
      }
    };

    Promise.all([
      fetchData(
        "https://zola.technology/api/company/feedback/get-filtered-feedback/",
        setFilteredFeedback1
      ),
      fetchData(
        "https://zola.technology/api/company/feedback/list-categorised/",
        setCategorizedFeedback
      ),
    ]).finally(() => setLoading(false));
  }, []);

  const negativeFeedback = categorizedFeedback?.negative_feedback_count || 0;
  const positiveFeedback = categorizedFeedback?.positive_feedback_count || 0;
  const neutFeedback = categorizedFeedback?.neutral_feedback_count || 0;
  const totalFeedback = filteredFeedback1[0]?.feedback_count || 0;

  // Calculate percentages with conditional checks to avoid division by zero
  const posPercent = totalFeedback
    ? ((positiveFeedback * 100) / totalFeedback).toFixed(1)
    : "0";
  const negPercent = totalFeedback
    ? ((negativeFeedback * 100) / totalFeedback).toFixed(1)
    : "0";

  const neutPercent = totalFeedback
    ? ((neutFeedback * 100) / totalFeedback).toFixed(1)
    : "0";
  const renderStars = (count) => {
    return Array.from({ length: count }, (_, index) => (
      <FaStar key={index} className="text-yellow-500" />
    ));
  };

  // Safely filter resolved and unresolved feedback
  const resolvedFeedback =
    filteredFeedback1[0]?.feedback?.filter(
      (feedback) => feedback.resolved === true
    ) || [];
  const unresolvedFeedback =
    filteredFeedback1[0]?.feedback?.filter(
      (feedback) => feedback.resolved === false
    ) || [];

  // Display based on active tab
  const displayedFeedback =
    activeTab === "responded" ? resolvedFeedback : unresolvedFeedback;

  // Open Modal with selected feedback details
  const openModal = async (feedback) => {
    setSelectedFeedback(feedback);

    const employeeId = feedback?.employee?.id || null;

    if (employeeId) {
      try {
        // Get token from localStorage
        const token = localStorage.getItem("token");

        const response = await fetch(
          `https://zola.technology/api/company/employees/performance/ratings/categorized-totals/${employeeId}/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Add token to the Authorization header
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch rating data");
        }

        const ratingData = await response.json();

        // Update the rating section in the modal
        setRatingData(ratingData);
      } catch (error) {
        console.error("Error fetching rating data:", error);
      }
    } else {
      // Provide fallback rating data
      setRatingData({
        rating_of_1_count: 0,
        rating_of_2_count: 0,
        rating_of_3_count: 0,
        rating_of_4_count: 0,
        rating_of_5_count: 0,
      });
    }

    setShowModal(true);
  };

  // Close Modal
  const closeModal = () => {
    setSelectedFeedback(null);
    setShowModal(false);
  };

  // Function to get initials from a full name
  const getInitials = (name) => {
    // Check if the name is a valid string
    if (typeof name !== "string" || !name) return "";

    const nameParts = name.trim().split(" ");

    // If there's only one word, return the first letter of that word
    if (nameParts.length === 1) {
      return nameParts[0][0].toUpperCase();
    }

    // If there are multiple words, take the first letter of the first two words
    const initials = nameParts[0][0] + nameParts[1][0];

    return initials.toUpperCase();
  };

  console.log(selectedFeedback, "selected feed");

  return (
    <div className="md:p-2 min-h-screen">
      <div className="flex  text-[#282A3E] items-center mb-4 ">
        <DashboardHeader title="Feedback" />
      </div>

      <div className="bg-white rounded-lg h-auto ">
        {/* Header Section */}
        <div className="flex justify-between items-center  border-b border-[#EAEAEC] px-6 py-4">
          <p className="flex text-[20px] font-medium text-[#454861] items-center gap-2">
            <PiChatsFill className="text-blue-600" /> Feedback
          </p>
          {/* <select
            name="feedback-filter"
            id="feedback-filter"
            className="bg-white text-[14px] font-medium text-[#454861] focus:outline-none "
          >
            <option value="month">This Month</option>
            <option value="year">This Year</option>
          </select> */}
        </div>

        <div className="px-6 pt-2 pb-4 ">
          <p className="text-[28px] font-semibold text-[#454861]">
            Total Feedbacks: {totalFeedback}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-8">
            <div className="flex flex-col gap-2 items-start w-full md:w-2/3">
              <div className="flex items-center gap-2">
                <PiThumbsUpFill className="text-green-500 w-[28px] h-[25px]" />
                <p className="text-[#6F707E] text-[14px] font-medium">
                  Positive
                  <p className="text-[20px] font-bold text-[#454861]">
                    {posPercent}%
                  </p>
                </p>
              </div>

              <div className="w-full bg-gray-200 rounded-full h-2">
                <div
                  className="bg-green-500 h-2 rounded-full"
                  style={{ width: `${posPercent}%` }}
                ></div>
              </div>
            </div>
            <div className="flex flex-col gap-2 items-start w-full md:w-2/3">
              <div className="flex items-center gap-2">
                <PiThumbsDownFill className="text-red-500 w-[28px] h-[25px]" />
                <p className="text-[#6F707E] text-[14px] font-medium">
                  Negative
                  <p className="text-[20px] font-bold text-[#454861]">
                    {negPercent}%
                  </p>
                </p>
              </div>

              <div className="w-full bg-gray-200 rounded-full h-2">
                <div
                  className="bg-red-500 h-2 rounded-full"
                  style={{ width: `${negPercent}%` }}
                ></div>
              </div>
            </div>
            <div className="flex flex-col gap-2 items-start w-full md:w-2/3">
              <div className="flex items-center gap-2">
                <FaHandFist className="text-[#FFBC07] w-[32px] h-[32px]" />
                <p className="text-[#6F707E] text-[14px] font-medium">
                  Neutral
                  <p className="text-[20px] font-bold text-[#454861]">
                    {neutPercent}%
                  </p>
                </p>
              </div>

              <div className="w-full bg-gray-200 rounded-full h-2">
                <div
                  className="bg-[#FFBC07] h-2 rounded-full"
                  style={{ width: `${neutPercent}%` }}
                ></div>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-12">
            {/* Responded Badge */}
            <div className="flex items-center px-4 py-2 bg-blue-100 rounded-full text-blue-600 font-medium">
              <div className="w-4 h-4 bg-blue-600 rounded-full flex items-center justify-center text-white mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              Responded:{" "}
              {resolvedFeedback.length > 0
                ? resolvedFeedback.length
                : "No responses yet"}
            </div>

            {/* No Response Badge */}
            <div className="flex items-center px-4 py-2 bg-red-100 rounded-full text-red-600 font-medium">
              <div className="w-4 h-4 bg-red-600 rounded-full flex items-center justify-center text-white mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              No Response:{" "}
              {unresolvedFeedback.length > 0
                ? unresolvedFeedback.length
                : "No pending responses"}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-auto">
        {/* Sticky Tabs */}
        <div className="sticky  my-6 w-full">
          <div className="flex items-center rounded-full border border-[#EAEAEC] w-full h-[48px] ">
            {/* Responded Button */}
            <button
              aria-label="Responded"
              className={`w-1/2 px-6 py-3 text-sm rounded-full font-medium focus:outline-none transition-all duration-200 ${
                activeTab === "responded"
                  ? "bg-white text-blue-600  h-[48px]"
                  : "text-gray-500 bg-[#F5F8FF]"
              }`}
              style={{
                boxShadow:
                  activeTab === "responded"
                    ? "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814"
                    : "none",
              }}
              onClick={() => setActiveTab("responded")}
            >
              Responded
            </button>

            {/* No Response Button */}
            <button
              aria-label="No Response"
              className={`w-1/2 px-6 py-3 text-sm rounded-full font-medium focus:outline-none transition-all duration-200  ${
                activeTab === "no-response"
                  ? "bg-white text-red-600  h-[48px]"
                  : "text-gray-500 bg-[#F5F8FF]"
              }`}
              style={{
                boxShadow:
                  activeTab === "no-response"
                    ? "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814"
                    : "none",
              }}
              onClick={() => setActiveTab("no-response")}
            >
              No Response
            </button>
          </div>
        </div>

        {/* Scrollable Feedbacks Section */}
        <div className="flex-1 overflow-y-auto no-scrollbar">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-0">
            {displayedFeedback.map((feedback, index) => (
              <div
                key={index}
                className="bg-white p-4 rounded-[12px]  hover:scale-95 transition"
              >
                <div className="flex justify-between">
                  <div>
                    <h3 className="font-medium text-[20px] text-[#454861]">
                      {feedback.author_name}
                    </h3>
                    <span className=" text-[#6F707E] text-[14px]">
                      {new Date(feedback.created_at).toLocaleDateString()}
                    </span>
                  </div>
                  <button
                    aria-label="Details"
                    className="px-4 h-[37px] bg-[#0C4EF8] rounded-[4px] text-white font-semibold text-[14px] hover:shadow-lg transition"
                    onClick={() => openModal(feedback)}
                  >
                    Details
                  </button>
                </div>
                <p className="my-2 text-[#6F707E] font-normal text-[16px]">
                  {feedback.text_info}
                </p>
                <div className="flex">{renderStars(feedback.rating)}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && selectedFeedback && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 p-4">
          <div className="bg-white rounded-lg w-full max-w-2xl p-6 shadow-2xl relative">
            {/* Close Button */}
            <button
              aria-label="Close Modal"
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition duration-200"
            >
              <MdClose size={24} />
            </button>

            {/* Modal Header */}
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">
              Review Details
            </h2>

            <div className="space-y-4">
              <div className="flex flex-col md:flex-row justify-between gap-4">
                <div className="border border-gray-200 p-4 rounded-lg shadow-sm w-full md:w-1/2 lg:w-1/3">
                  {/* Employee Label */}
                  <p className="text-xs font-semibold text-gray-400 mb-1">
                    Employee
                  </p>

                  {/* Employee Name */}
                  <p className="text-xl font-semibold text-gray-800 mb-2">
                    {selectedFeedback?.employee?.name
                      ? selectedFeedback.employee.name
                      : "Employee Name Not Available"}
                  </p>

                  {/* Taps and Rating */}
                  <div className="flex items-center text-gray-600">
                    <span className="text-lg font-medium">
                      {selectedFeedback?.employee?.number_of_taps ?? "N/A"}
                    </span>
                    <span className="text-xs ml-1">taps</span>
                    <span className="ml-3 text-lg font-medium flex items-center gap-1">
                      {selectedFeedback?.employee_rating || "N/A"}
                      <FaStar className="text-yellow-500" />
                    </span>
                  </div>
                </div>

                {/* Rating Distribution */}
                <div className="w-full md:w-1/2 p-4 rounded-lg">
                  <p className="text-sm font-semibold text-gray-500 mb-3">
                    Employee rating Distribution
                  </p>
                  <div className="flex flex-col gap-2">
                    {[
                      {
                        rating: "5",
                        count: ratingData.rating_of_5_count,
                        color: "bg-green-400",
                      },
                      {
                        rating: "4",
                        count: ratingData.rating_of_4_count,
                        color: "bg-purple-400",
                      },
                      {
                        rating: "3",
                        count: ratingData.rating_of_3_count,
                        color: "bg-pink-400",
                      },
                      {
                        rating: "2",
                        count: ratingData.rating_of_2_count,
                        color: "bg-yellow-400",
                      },
                      {
                        rating: "1",
                        count: ratingData.rating_of_1_count,
                        color: "bg-red-400",
                      },
                    ].map((level, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between"
                      >
                        <span className="text-xs text-gray-500">
                          {level.rating}
                        </span>
                        <div className="w-full bg-gray-200 rounded-full h-2 mx-2">
                          <div
                            className={`${level.color} h-2 rounded-full`}
                            style={{
                              width: `${Math.min(
                                (level.count / 100) * 100,
                                100
                              )}%`,
                            }}
                          ></div>
                        </div>
                        <span className="text-xs text-gray-500">
                          {level.count}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Reviewer Section */}
              <div className="flex items-start justify-between bg-gray-50 p-4 rounded-lg">
                <div>
                  <p className="text-sm font-semibold text-gray-500">
                    Reviewer
                  </p>
                  <p className="text-lg font-bold text-gray-800">
                    {selectedFeedback?.author_name || "Anonymous"}
                  </p>
                  {selectedFeedback?.phone_number && (
                    <p className="text-gray-500 flex items-center gap-2">
                      <FaPhoneAlt className="text-gray-400" />{" "}
                      {selectedFeedback.phone_number}
                    </p>
                  )}
                </div>
                <p className="text-sm text-gray-400">
                  {new Date(selectedFeedback?.created_at).toLocaleDateString()}
                </p>
              </div>

              {/* Review Text Section */}
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="text-sm font-semibold text-gray-500 mb-2">
                  Review
                </p>
                <p className="text-gray-700 text-sm">
                  {selectedFeedback?.text_info ||
                    "No additional information provided."}
                </p>
                <span className="text-lg font-medium flex items-center gap-1">
                  {selectedFeedback?.rating || "N/A"}
                  <FaStar className="text-yellow-500" />
                </span>
              </div>

              {/* Callback Link */}
              {selectedFeedback?.callback && (
                <div className="bg-gray-50 p-4 rounded-lg flex items-center gap-2">
                  <MdOutlineLink className="text-blue-500 text-lg" />
                  <a
                    href={selectedFeedback.callback}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 underline hover:text-blue-800 transition duration-200"
                  >
                    View Full Feedback
                  </a>
                </div>
              )}

              {/* Resolved Status */}
              <div className="flex items-center gap-3 bg-gray-50 p-4 rounded-lg">
                {selectedFeedback?.resolved ? (
                  <FaCheckCircle className="text-green-500 text-lg" />
                ) : (
                  <FaTimesCircle className="text-red-500 text-lg" />
                )}
                <p className="text-gray-700">
                  <span className="font-semibold">Resolved:</span>{" "}
                  {selectedFeedback?.resolved ? "Yes" : "No"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Feedback;
