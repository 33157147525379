import axios from "axios";

export const registerUser = async (userData) => {
  try {
    const response = await axios.post(
      "https://zola.technology/api/company/signup/",
      userData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    let message = "Error registering user";

    if (error.response && error.response.data) {
      const responseData = error.response.data;

      if (responseData.username) {
        message = responseData.username[0];
      } else if (responseData.email) {
        message = responseData.email[0];
      } else if (responseData.phone_number) {
        message = responseData.phone_number[0];
      }
    } else if (error.message) {
      message = error.message;
    }

    throw new Error(message);
  }
};

export const getToken = async (credentials) => {
  try {
    const response = await axios.post(
      `https://zola.technology/api/token/`,
      credentials
    );
    return response;
  } catch (error) {
    const message = error.response?.data?.detail || "Error fetching token";
    throw new Error(message);
  }
};

export const refreshToken = async (refreshToken) => {
  try {
    const response = await axios.post(
      `https://zola.technology/api/token/refresh/`,
      { refresh: refreshToken }
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.detail || "Error refreshing token");
  }
};

export const addEmployees = (employees, token) => {
  return axios.post(
    `https://zola.technology/api/company/add_employees`,
    { employees },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deleteEmployee = (username, token) => {
  return axios.delete(
    `https://zola.technology/api/company/delete/${username}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getUserProfile = (username, token) => {
  return axios.get(`https://zola.technology/api/company/get/${username}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const listCompanies = async (token) => {
  try {
    const response = await axios.get(
      `https://zola.technology/api/company/get-all/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.detail || "Error fetching companies");
  }
};

export const makePayment = async (
  username,
  total,
  phone_number,
  transactionType,
  subscriptionType,
  subscriptionAmount
) => {
  try {
    const requestBody = {
      username: username,
      unit_amount: total-subscriptionAmount,
      phone_number: phone_number,
      subscription_interval: subscriptionType,
      subscription_amount: subscriptionAmount,
      transaction_type: transactionType,
    };

    const response = await axios.post(
      "https://zola.technology/api/company/make-payment/",
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data.url;
  } catch (error) {
    throw new Error(error.response?.data?.error);
  }
};

export const listReviews = async (token) => {
  try {
    const response = await axios.get(
      `https://zola.technology/api/company/reviews/list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.detail || "Error fetching reviews");
  }
};
export const addFeedback = async (feedbackData) => {
  try {
    const response = await axios.post(
      `https://zola.technology/api/company/feedback/add/`,
      feedbackData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.detail || "Error adding feedback");
  }
};

export const listFeedback = async (currentPage, pageSize) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }

    const response = await axios.get(
      `https://zola.technology/api/company/feedback/list/?page=${currentPage}&page_size=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (error) {
    if (
      error.response?.status === 401 ||
      error.response?.data?.code === "token_not_valid"
    ) {
      console.log("Token expired or invalid. Refreshing token...");
      // Implement your token refresh logic here
    }
    throw new Error(error.response?.data?.detail || "Error fetching feedback");
  }
};

export const feedBackList = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }

    const response = await axios.get(
      `https://zola.technology/api/company/feedback/list-categorised/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (error) {
    if (
      error.response?.status === 401 ||
      error.response?.data?.code === "token_not_valid"
    ) {
      console.log("Token expired or invalid. Refreshing token...");
      // Implement your token refresh logic here
    }
    throw new Error(error.response?.data?.detail || "Error fetching feedback");
  }
};

export const listFeedbackWithoutParams = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }

    const response = await axios.get(
      `https://zola.technology/api/company/feedback/list/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (error) {
    if (
      error.response?.status === 401 ||
      error.response?.data?.code === "token_not_valid"
    ) {
      console.log("Token expired or invalid. Refreshing token...");
      // Implement your token refresh logic here
    }
    throw new Error(error.response?.data?.detail || "Error fetching feedback");
  }
};

// Function to get a company profile
export const getCompanyProfile = async (token) => {
  try {
    console.log("Bearer " + token); // Log the token to verify format and value
    const response = await axios.get(
      `https://zola.technology/api/company/profile/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.detail || "Error fetching company profile"
    );
  }
};

export const getLinkInteractions = async (token, selectedPeriod) => {
  try {
    const response = await axios.get(
      `https://zola.technology/api/company/employees/analytics/get-all-with-taps/?filter=${selectedPeriod}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.detail || "Error fetching link interactions"
    );
  }
};
