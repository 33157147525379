import React, { useEffect, useState } from "react";
import { FaExclamationTriangle, FaStar } from "react-icons/fa";
import { MdError, MdVerified } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import EditForm from "./EditForm";
import { ToastContainer, toast } from "react-toastify";

const EmployeeProfile = () => {
  const navigate = useNavigate();
  const { employeeId } = useParams(); // Extract employeeId from URL parameters
  const [employeeData, setEmployeeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const token = localStorage.getItem("token"); // Get token from localStorage
        if (!token) {
          throw new Error("No token found");
        }

        const response = await fetch(
          `https://zola.technology/api/company/employees/details/get/${employeeId}/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        setEmployeeData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployeeData();
  }, [employeeId]); // Re-run if employeeId changes

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // console.log("Employee data", employeeData);

  const handleUpdate = (index) => {
    setSelectedItemIndex(index);
    // console.log(index);
    setShowPopup(true);
  };

  const handleEditFormClose = () => setShowPopup(false);

  const handleUpdateData = async (updatedValues) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }

    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/update/${employeeData.employee.id}/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: updatedValues.name,
            username: updatedValues.username,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        // console.log("Error Data:", errorData); // Log to check structure
        toast.error(
          errorData?.username?.[0] ||
            errorData?.message ||
            "Error updating employee"
        );
        throw new Error("Network response was not ok");
      }

      const updatedData = [...employeeData];
      updatedData[selectedItemIndex] = {
        ...updatedData[selectedItemIndex],
        name: updatedValues.name,
        username: updatedValues.username,
      };
      setData(updatedData);
      toast.success("Employee data updated successfully!");
    } catch (error) {
      setError(error.message);
      toast.error(`Update Error: ${error.message}`);
    }
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true); // Open the delete modal
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false); // Close the delete modal
  };

  const handleDelete = () => {
    // console.log(`Employee ${employeeData?.employee.name} has been deleted!`);
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }

    setLoading(true);
    try {
      const response = fetch(
        `https://zola.technology/api/company/employees/delete/${employeeData?.employee.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // if (response.status !== 204) throw new Error("Error deleting employee");

      // setData(data.filter((_, i) => i !== index));
      toast.success(`${employeeData?.employee.name} deleted  successfully!`);
      navigate(-1);
    } catch (error) {
      setError(error.message);
      toast.error(`Delete Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="p-8 min-h-screen">
      <ToastContainer position="top-right" autoClose={3000} />

      {/* Header Section */}
      <div className="flex items-center gap-6 mb-6">
        {/* <img
          src=""
          alt={employeeData.employee.name}
          className="w-32 h-32 rounded-full object-cover shadow-lg 
             ring-4 ring-offset-2 ring-white"
        /> */}

        <p className="w-32 h-32 rounded-full flex items-center justify-center text-white font-bold text-2xl bg-blue-600 object-cover shadow-lg ring-4 ring-offset-2 ring-white">
          {employeeData.employee.name
            .split(" ") // Split name into parts
            .slice(0, 2) // Take only the first two parts
            .map((n) => n[0].toUpperCase()) // Get the first letter of each part and capitalize it
            .join("") || employeeData.employee.name[0].toUpperCase()}{" "}
          {/* Fallback to the first letter if there's only one name part */}
        </p>

        <div className="">
          <h1 className="text-3xl font-normal">
            {employeeData.employee.name.split(" ")[0]} <br />
            {employeeData.employee.name.split(" ")[1] || ""}
          </h1>
          {/* <div className={`text-lg flex items-center ${
                employeeData.performance_label === "Poor Employee"
                  ? "bg-red-100 text-red-600"
                  : "bg-blue-100 text-blue-600"
              } space-x-2 bg-blue-50 px-3 py-1 rounded-full w-fit mt-1`}>
            <div
              className={`flex items-center space-x-1 rounded-md`}
            >
              {employeeData.performance_label === "Poor Employee" ? (
                <MdError className="text-red-600" />
              ) : (
                <MdVerified className="text-blue-600" />
              )}
              <span>{employeeData.performance_label}</span>
            </div>
          </div> */}
        </div>
        <div className="space-x-2">
          <div className="flex gap-2 items-center">
            <button
              aria-label="Update"
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
              onClick={handleUpdate}
            >
              Update
            </button>
            <button
              aria-label="Delete"
              className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition"
              onClick={() => handleDeleteClick()} // Go back to the employee list
            >
              Delete
            </button>
          </div>
          {/* Ensure the URL is visible and styled */}
          <div className="mt-2">
            <a
              href={employeeData.employee.employee_url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-black italic break-all"
            >
              {employeeData.employee.employee_url}
            </a>
          </div>
        </div>
      </div>

      {/* Statistics Section */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        {/* Ranking Card */}
        <div className="p-4 bg-white rounded-xl">
          <h2 className="text-sm font-medium text-gray-500">Ranking</h2>
          <p className="text-2xl font-semibold mt-1">{employeeData.rank}</p>
        </div>

        {/* Average Rating Card */}
        <div className="p-4 bg-white rounded-xl">
          <h2 className="text-sm font-medium text-gray-500">Average Rating</h2>
          <div className="flex items-center space-x-1 mt-1">
            <span className="text-2xl font-semibold">
              {employeeData.average_rating}
            </span>
            <FaStar className="text-yellow-500" />
          </div>
        </div>

        {/* Rewards Card */}
        <div className="p-4 bg-white rounded-xl">
          <h2 className="text-sm font-medium text-gray-500">Rewards</h2>
          <p className="text-2xl font-semibold mt-1">
            {employeeData.reward_currency} {employeeData.reward_total}
          </p>
        </div>

        {/* Reviews Card */}
        <div className="p-4 bg-white rounded-xl">
          <h2 className="text-sm font-medium text-gray-500">Feedbacks</h2>

          <p className="text-2xl font-semibold mt-1">
            {employeeData.total_feedback}
          </p>
        </div>
      </div>

      {/* Reviews Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {employeeData.feedback.length > 0 ? (
          employeeData.feedback.map((review, index) => (
            <div key={index} className="p-4 bg-white rounded-lg">
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-md font-semibold">{review.author_name}</h3>
                <span className="text-sm text-gray-500">
                  {new Date(review.insertion_time).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </span>{" "}
              </div>
              <p className="text-sm text-gray-700 mb-2">{review.text_info}</p>
              <div className="flex items-center space-x-1">
                <span>{review.rating}</span>
                <FaStar className="text-yellow-500" />
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500">Employee&apos;s reviews will appear here</p>
        )}
      </div>
      {showPopup && employeeData ? (
        <EditForm
          selectedItem={employeeData.employee} // Pass the selected item
          onUpdate={handleUpdateData}
          onClose={handleEditFormClose}
        />
      ) : null}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl p-6 w-[90%] max-w-sm text-center space-y-4">
            <FaExclamationTriangle className="text-red-500 text-5xl mx-auto" />
            <h2 className="text-2xl font-semibold">Delete Employee?</h2>
            <p className="text-gray-600">This action cannot be undone.</p>
            <div className="flex justify-center space-x-4 mt-4">
              <button
                aria-label="Cancel"
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition"
                onClick={handleDeleteCancel} // Closes the delete confirmation modal
              >
                Cancel
              </button>
              <button
                aria-label="Delete"
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition"
                onClick={handleDelete}// Confirms deletion of the selected employee
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeProfile;
