import { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FaStar } from "react-icons/fa";

const incrementTapCount = async (company_username, employee_username) => {
  try {
    const response = await fetch(
      `https://zola.technology/api/company/employees/update/${company_username}/${employee_username}/increment-taps/`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      console.log("Tap count incremented successfully");
    } else {
      console.error("Failed to increment tap count");
    }
  } catch (error) {
    console.error("Error while sending tap data to backend", error);
  }
};

function Emoji() {
  const { company_username, employee_username } = useParams();
  const { state } = useLocation();
  const { feedback, employeeName } = state || {}; // Destructure state values
  const [companyName, setCompanyName] = useState("");
  const [preferredBusinessName, setPreferredBusinessName] = useState("");
  const [profileImage, setProfileImage] = useState(""); // State for profile image
  const [selectedRating, setSelectedRating] = useState(0); // State for selected rating
  const [companyRateStyle, setCompanyRateStyle] = useState(""); // State for rating style
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBusinessName = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/get/${company_username}`
        );
        const preferredName = response.data.preferred_business_name;
        setPreferredBusinessName(preferredName);
        setCompanyName(response.data.company_name);
        setProfileImage(response.data.profile_image); // Store profile image URL
        setCompanyRateStyle(response.data.company_rate_style); // Get rating style from API
      } catch (error) {
        console.error("Error fetching company profile:", error);
      }
    };

    fetchBusinessName();
  }, [company_username]);

  const handleRatingClick = (rating) => {
    setSelectedRating(rating);
    const emojis = ["angry", "sad", "neutral", "happy", "love"];
    const emoji = emojis[rating - 1];

    incrementTapCount(company_username, employee_username);

    const emojiEncoded = encodeURIComponent(emoji);
    const feedbackEncoded = encodeURIComponent(feedback || "");
    const employeeNameEncoded = encodeURIComponent(employeeName || "");

    // Navigate to the appropriate page based on the rating (emoji)
    if (rating <= 2) {
      navigate(
        `/negative-review-prompt/${encodeURIComponent(
          company_username
        )}/${encodeURIComponent(
          employee_username
        )}/${emojiEncoded}?feedback=${feedbackEncoded}&employeeName=${employeeNameEncoded}`
      );
    } else {
      navigate(
        `/review-prompt/${encodeURIComponent(
          company_username
        )}/${encodeURIComponent(
          employee_username
        )}/${emojiEncoded}?feedback=${feedbackEncoded}&employeeName=${employeeNameEncoded}`
      );
    }
  };

  // Function to render emojis based on the rating
  const renderEmojiRating = (rating) => {
    const emojis = ["😡", "😞", "😐", "😊", "😍"];
    return emojis[rating - 1];
  };

  return (
    <div className="min-h-screen relative flex flex-col items-center justify-center bg-white p-6">
      {/* Profile Image */}
      <div className="relative">
        {profileImage && (
          <img
            src={profileImage}
            alt="Company Profile"
            className="w-24 h-24 rounded-full mb-4 border-4 border-blue-300 shadow-lg"
          />
        )}
      </div>
      <p className="text-center font-serif text-4xl font-bold text-black mb-8">
        {preferredBusinessName || companyName}
      </p>
      <div className="w-full max-w-sm text-center">
        <h2 className="text-2xl font-semibold mb-6">
          How was your experience today?
        </h2>
        <div className="flex justify-around">
          {companyRateStyle === "stars" ? (
            // Render star rating
            [...Array(5)].map((_, index) => {
              const starValue = index + 1;
              return (
                <FaStar
                  key={starValue}
                  size={40}
                  className={`cursor-pointer transition-colors duration-300 ${
                    starValue <= selectedRating ? "text-yellow-500" : "text-gray-300"
                  }`}
                  onClick={() => handleRatingClick(starValue)}
                />
              );
            })
          ) : companyRateStyle === "emojis" ? (
            // Render emoji rating
            [...Array(5)].map((_, index) => {
              const emojiValue = index + 1;
              return (
                <span
                  key={emojiValue}
                  className={`cursor-pointer transition-colors duration-300 text-2xl ${
                    emojiValue <= selectedRating
                      ? "text-yellow-500"
                      : "text-gray-300"
                  }`}
                  onClick={() => handleRatingClick(emojiValue)}
                >
                  {renderEmojiRating(emojiValue)}
                </span>
              );
            })
          ) : (
            <p>Rating style not available</p>
          )}
        </div>
      </div>
      <footer className="absolute bottom-2 text-center text-gray-600">
        <p className="text-sm">Powered by Zola Technologies</p>
      </footer>
    </div>
  );
}

export default Emoji;
