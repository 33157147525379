import { FiUpload, FiX } from "react-icons/fi";

const CoverPictureModal = ({ showModal, setShowModal, onUpload }) => {
  if (!showModal) return null;

  return (
    <div className="fixed p-4 md:p-0 inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg w-full">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-800 dark:text-white">
            Upload Cover Picture
          </h2>
          <button
            aria-label="Close Modal"
            onClick={() => setShowModal(false)}
            className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
          >
            <FiX size={24} />
          </button>
        </div>

        {/* Content Section */}
        <div className="flex flex-col items-center gap-4">
          <label
            htmlFor="cover-upload"
            className="cursor-pointer px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200"
          >
            <FiUpload className="inline mr-2" /> Choose Picture
          </label>
          <input
            type="file"
            id="cover-upload"
            className="hidden"
            onChange={onUpload} // Call the upload handler on file selection
          />

          <p className="text-sm text-gray-500 dark:text-gray-400">
            Supported formats: JPG, PNG, GIF (Max 5MB)
          </p>
        </div>

        {/* Action Buttons */}
        <div className="mt-6 flex justify-end space-x-4">
          <button
            aria-label="Close Modal"
            onClick={() => setShowModal(false)}
            className="px-4 py-2 rounded-md bg-gray-200 hover:bg-gray-300 dark:bg-gray-600 dark:hover:bg-gray-500 transition duration-200"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoverPictureModal;
