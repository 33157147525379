import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import { getToken, getCompanyProfile } from "../../api";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Login() {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const response = await getToken({ username, password });
      const token = response.data.access;
      const rtoken = response.data.refresh;

      // After successful login, fetch user profile
      const profile = await getCompanyProfile(token);
      localStorage.setItem("userData", JSON.stringify(profile));

      // Navigate based on subscription status
      if (profile.subscription === null) {
        navigate("/setup-form");
      } else {
        login({
          username,
          token,
          firstName: profile.first_name,
          rtoken,
        });
        navigate("/dashboard");
      }
    } catch (error) {
      toast.error(error.message);
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  return (
    <section className="flex items-center justify-center w-full min-h-screen p-6 md:p-12 bg-gray-100">
      <div className="w-full max-w-md sm:max-w-lg bg-white shadow-lg rounded-lg p-6 md:p-8">
        <p className="text-xl sm:text-2xl font-bold text-center mb-4 sm:mb-6">Log in to Your Account</p>
        <form className="flex flex-col gap-4 sm:gap-6" onSubmit={handleEmailLogin}>
          <input
            type="text"
            className="border rounded-md p-3 text-base bg-gray-50 focus:bg-white focus:border-gray-300 transition-colors"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <div className="relative">
            <input
              type={passwordType}
              className="border rounded-md p-3 text-base bg-gray-50 focus:bg-white focus:border-gray-300 transition-colors w-full"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span
              className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
              onClick={togglePasswordVisibility}
            >
              {passwordType === "password" ? <FaEye /> : <FaEyeSlash />}
            </span>
          </div>

          <button
            className={`bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 disabled:bg-gray-400 transition-colors ${loading ? "cursor-not-allowed" : "cursor-pointer"}`}
            type="submit"
            disabled={loading}
            aria-busy={loading}
            aria-label={loading ? "Logging in, please wait" : "Log in to your account"}
          >
            {loading ? "Logging in..." : "Log in"}
          </button>
        </form>
        {error && <p className="text-red-500 text-sm text-center mt-4">{error}</p>}

        <p className="text-center mt-4 text-sm sm:text-base">
          Don&apos;t have an account?{" "}
          <Link to="/account-type" className="text-blue-500 hover:underline">
            Sign up here
          </Link>
        </p>
        <p className="text-center mt-4 text-sm sm:text-base">
          Forgot password? Click{" "}
          <Link to="/forgot-password" className="text-blue-500 hover:underline">
            here
          </Link>{" "}
          to reset
        </p>
      </div>
      <ToastContainer />
    </section>
  );
}

export default Login;
