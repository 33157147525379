import React, { useState } from 'react';

const NotificationToggle = ({ label, description, enabled, onToggle }) => {
  return (
    <div className="flex justify-between items-center py-3 border-b border-gray-200">
      <div>
        <h4 className="text-lg font-medium">{label}</h4>
        <p className="text-gray-600">{description}</p>
      </div>
      <div>
        <label className="relative inline-flex items-center cursor-pointer">
          <input 
            type="checkbox" 
            className="sr-only peer" 
            checked={enabled} 
            onChange={onToggle} 
          />
          <div className="w-11 h-6 bg-gray-200 rounded-full peer-focus:ring-4 peer-focus:ring-black peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
        </label>
      </div>
    </div>
  );
};

const Notifications = () => {
  const [inAppNotifications, setInAppNotifications] = useState({
    mobilePush: true,
    desktopPush: false,
    dailyDigest: false,
  });

  const [emailNotifications, setEmailNotifications] = useState({
    workspaceActivity: true,
    alwaysSendEmails: false,
    emailDigest: false,
  });

  const toggleInAppNotification = (key) => {
    setInAppNotifications((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const toggleEmailNotification = (key) => {
    setEmailNotifications((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* In-app Notifications Section */}
      <h3 className="text-2xl font-semibold mb-6">In-app Notifications</h3>
      <NotificationToggle
        label="Mobile push notifications"
        description="Receive push notifications on mentions and comments via your mobile app"
        enabled={inAppNotifications.mobilePush}
        onToggle={() => toggleInAppNotification('mobilePush')}
      />
      <NotificationToggle
        label="Desktop push notifications"
        description="Receive push notifications on mentions and comments immediately on your desktop app"
        enabled={inAppNotifications.desktopPush}
        onToggle={() => toggleInAppNotification('desktopPush')}
      />
      <NotificationToggle
        label="Daily digest"
        description="Includes Productivity stats and tasks due today. Sent every morning."
        enabled={inAppNotifications.dailyDigest}
        onToggle={() => toggleInAppNotification('dailyDigest')}
      />

      {/* Email Notifications Section */}
      <h3 className="text-2xl font-semibold mt-10 mb-6">Email Notifications</h3>
      <NotificationToggle
        label="Activity in your workspace"
        description="Receive emails when you get comments, mentions, page invites, reminders, access requests, and property changes"
        enabled={emailNotifications.workspaceActivity}
        onToggle={() => toggleEmailNotification('workspaceActivity')}
      />
      <NotificationToggle
        label="Always send email notifications"
        description="Receive emails about activity in your workspace, even when you're active on the app"
        enabled={emailNotifications.alwaysSendEmails}
        onToggle={() => toggleEmailNotification('alwaysSendEmails')}
      />
      <NotificationToggle
        label="Email digest"
        description="Receive email digests every 8 hours for changes to pages you’re subscribed to"
        enabled={emailNotifications.emailDigest}
        onToggle={() => toggleEmailNotification('emailDigest')}
      />

      <div className="flex justify-end mt-8">
        <button aria-label='Save' className="bg-black text-white px-4 py-2 rounded-lg mr-2">
          Save Changes
        </button>
        <button aria-label='Cancel' className="bg-gray-200 text-black px-4 py-2 rounded-lg">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Notifications;
