import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { addFeedback } from "../../api";
import { useRating } from "../../context/RatingContext";

const Form = () => {
  const { company_username, employee_username, emoji } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  
  const { selectedExperienceRating, selectedTeamRating } = location.state || {};

  console.log(selectedExperienceRating, selectedTeamRating)
  // Extract rating from URL parameters
  const queryParams = new URLSearchParams(location.search);
  const employeeFeedback = queryParams.get("feedback")
  
  const [rating, setRating] = useState(""); // Original rating
  const [phoneNumber, setPhoneNumber] = useState("");
  const [textInfo, setTextInfo] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [message, setMessage] = useState("");
  const [emojiMessage, setEmojiMessage] = useState("");
  const [employeeFeedbackState, setEmployeeFeedbackState] = useState(employeeFeedback || "");
  const [employeeName, setEmployeeName] = useState("");
  const { employeeRating } = useRating()

  useEffect(() => {
    const getEmployeeName = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/employees/get/${company_username}/${employee_username}/employee-name/`
        );
        setEmployeeName(response.data.name); // Ensure 'response.data.name' matches the API's returned field
      } catch (error) {
        console.error("Error fetching employee profile:", error);
      }
    };
  
    getEmployeeName();
  }, [company_username, employee_username]); // Include dependencies to run the effect when params change
  

  useEffect(() => {
    const emojiRatings = {
      angry: 1,
      sad: 2,
      neutral: 3,
      happy: 4,
      love: 5,
    };

    const emojiMessages = {
      angry: "Please leave your name and phone number, someone from our team will reach out to you.",
      sad: "Please leave your name and phone number, someone from our team will reach out to you.",
      neutral: "Thank you for your feedback. Please let us know how we can make things better.",
      happy: "We're glad you had a good experience! Any additional comments?",
      love: "We're thrilled you loved your experience! Please share more details.",
    };

    if (emoji && emojiRatings[emoji]) {
      setRating(emojiRatings[emoji].toString());
      setEmojiMessage(emojiMessages[emoji]);
    }
  }, [emoji]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const feedbackData = {
      author_name: authorName,
      phone_number: phoneNumber,
      text_info: textInfo,
      rating: parseInt(selectedExperienceRating, 10),
      employee_rating: selectedTeamRating, // Add employee rating here
      // employee_feedback: employeeFeedbackState, // Add employee feedback here
      username: company_username,
      employee_username: employee_username,
    };
    console.log(feedbackData)

    try {
      await addFeedback(feedbackData);
      setMessage("Feedback submitted successfully!");
      setRating("");
      setPhoneNumber("");
      setTextInfo("");
      setAuthorName("");
      
      // Navigate to success page with the rating passed in state
      navigate("/success-page", { state: { rating: parseInt(rating, 10) } });
    } catch (error) {
      setMessage(`Failed to submit feedback: ${error.message}`);
    }
  };

  return (
    <section className="flex flex-col items-center justify-center min-h-screen p-6">
      <div className="w-full max-w-lg bg-white rounded-lg p-8">
        {employee_username && (
          <p className="text-lg font-light text-center text-gray-800 mb-4">
            Reviewing <span className="text-blue-500">{employeeName}</span>
          </p>
        )}
        <h1 className="text-[1.7rem] font-bold mb-4 text-center text-gray-800">
          Share your feedback
        </h1>
        {emojiMessage && (
          <p className="mb-4 text-center text-gray-600">{emojiMessage}</p>
        )}
        {message && <p className="mb-4 text-center text-red-500">{message}</p>}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <textarea
              value={textInfo}
              onChange={(e) => setTextInfo(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              placeholder="Your Feedback"
              rows="4"
            ></textarea>
          </div>
          {(rating === "1" || rating === "2") && (
            <div>
              <label className="block text-sm font-normal text-gray-700 mb-2">
                Phone Number <span className="text-xs font-light">(optional)</span>
              </label>
              <PhoneInput
                country={"us"}
                value={phoneNumber}
                onChange={setPhoneNumber}
                inputStyle={{
                  width: "100%",
                  borderRadius: "0.375rem",
                  borderColor: "#D1D5DB",
                  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                  paddingLeft: "3rem",
                  height: "2.5rem",
                  marginTop: "0.25rem",
                  display: "block",
                  border: "2px solid rgba(0, 0, 0, 0)",
                  outline: "none",
                  backgroundColor: "rgba(230, 230, 230, 0.6)",
                  fontSize: "1.1rem",
                  transition: ".3s",
                }}
              />
            </div>
          )}
          <div>
            <input
              type="text"
              value={authorName}
              onChange={(e) => setAuthorName(e.target.value)}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              placeholder="Your Name"
            />
          </div>
          <p className="text-center text-gray-500 text-sm">
            (This information will not be used for any other purpose than contacting you)
          </p>
          <button
            type="submit"
            className="w-full py-3 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            aria-label="Send Feedback"
          >
            Send Feedback
          </button>
        </form>
      </div>
    </section>
  );
};

export default Form;
