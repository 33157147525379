import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Multi.css";
import card from "./zola_card.jpg";
import stand from "./zola_stand.jpg";
import { makePayment } from "../../api";
import Zola from "./zola.jpg";
import both from "./zola_stand.jpg";
import axios from "axios";
import SubscriptionComponent from "./Subscription";
import { toast } from 'react-toastify';

const StepThree = ({ nextStep, prevStep, formData }) => {
  const { industryType, numberOfEmployees, numberOfCards } = formData;
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [cardPrice, setCardPrice] = useState(20);
  const [standPrice, setStandPrice] = useState(35);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const [shippingPrice, setShippingPrice] = useState("5");
  const [subscriptionType, setSubscriptionType] = useState("year");
  const [transactionType, setTransactionType] = useState("both");
  const [subscriptionAmount, setSubscriptionAmount] = useState(0);
  const [monthSubscription, setMonthSubscription] = useState(49);
  const [yearSubscription, setYearSubscription] = useState(
    userData.phone_number?.startsWith("254") ? 30000 : 480
  );
  const [selection, setSelection] = useState("both");
  const discountPercentage = 10; // Set a reasonable discount percentage, e.g., 10%
  const numberOfLocations = userData.number_of_locations
  console.log(numberOfLocations)

  useEffect(() => {
    async function fetchExchangeRate() {
      try {
        const response = await axios.get(
          `https://open.er-api.com/v6/latest/USD`
        );
        setExchangeRate(response.data.rates.KES);
      } catch (error) {
        console.error("Failed to fetch exchange rate:", error);
      }
    }
    fetchExchangeRate();
  }, []);

  useEffect(() => {
    if (userData.phone_number && exchangeRate) {
      if (userData.phone_number.startsWith("254")) {
        setCardPrice(Math.round(1500));
        setStandPrice(Math.round(2000));
        setShippingPrice(Math.round(0));
        setMonthSubscription(Math.round(3000));
        setYearSubscription(Math.round(2500 * 12));
        setCurrencySymbol("KES");
      } else {
        setCurrencySymbol("$");
      }
    }
  }, [userData.phone_number, exchangeRate]);

  const [cardCount, setCardCount] = useState(0);
  const [standCount, setStandCount] = useState(0);
  const [includeZolaStand, setIncludeZolaStand] = useState(false);
  const [isCheckoutDisabled, setIsCheckoutDisabled] = useState(false);

  useEffect(() => {
    if (industryType === "one_person") {
      setCardCount(1);
      setStandCount(1);
      setIncludeZolaStand(true);
    } else if (industryType === "team" && numberOfCards) {
      setCardCount(numberOfCards);
      setStandCount(0);
      setIncludeZolaStand(false);
    }
  }, [industryType, numberOfCards]);

  const incrementCardCount = () => setCardCount((prev) => Number(prev) + 1);
  const decrementCardCount = () =>
    setCardCount((prev) =>
      Math.max(
        prev - 1,
        industryType === "team" && numberOfCards ? numberOfCards : 1
      )
    );
  const incrementStandCount = () => setStandCount((prev) => prev + 1);
  const decrementStandCount = () =>
    setStandCount((prev) => Math.max(prev - 1, 1));

  useEffect(() => {
    if (subscriptionType === "month") {
      setSubscriptionAmount( numberOfLocations * monthSubscription);
    } else if (subscriptionType === "year") {
      setSubscriptionAmount( numberOfLocations * yearSubscription);
    } else {
      setSubscriptionAmount(0);
    }
  }, [subscriptionType]);

  const handleCheckout = async () => {
    try {
      setIsCheckoutDisabled(true);
  
      const username = userData.username;
      const phone_number = userData.phone_number;
      const total = calculateTotal();
  
      // Payment URL handling (if required)
      const paymentUrl = await makePayment(
        username,
        total,
        phone_number,
        transactionType,
        subscriptionType,
        subscriptionAmount
      );
  
      // Data to send in the PATCH request
      const data = {
        number_of_cards: cardCount,
        number_of_stands: standCount,
        unit_amount: total,
        company_username: username,
        phone_number: phone_number,
        subscription_interval: subscriptionType,
        subscription_amount: subscriptionAmount,
      };

      const authToken = localStorage.getItem('authToken')
      console.log(authToken)
  
      // Make the PATCH request to update the company data
      const response = await fetch("https://zola.technology/api/company/update/", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        console.log("Company data updated successfully");
        nextStep(paymentUrl); // Navigate to the payment URL if required
      } else {
        console.error("Failed to update company data");
      }
    } catch (error) {
      console.error("Error making payment:", error.message);
      alert(error.message);
      setIsCheckoutDisabled(false);
    }
  };
  

  const calculateTotal = () => {
    let subtotal = 0;
    if (industryType === "one_person") {
      if (selection === "card") {
        subtotal = cardCount * cardPrice;
      } else if (selection === "stand") {
        subtotal = standCount * standPrice;
      } else if (selection === "both") {
        subtotal = cardCount * cardPrice + standCount * standPrice;
      }
    } else if (industryType === "team" && numberOfCards) {
      subtotal = cardCount * cardPrice;
      if (includeZolaStand) {
        subtotal += standCount * standPrice;
      }
    }
    const shipping = Number(shippingPrice); // Ensure shippingPrice is a number
    const total = subtotal + shipping + subscriptionAmount; // Include subscription amount in total
    return isNaN(total) ? 0 : total; // Return a default value if total is NaN
  };

  const calculateDiscountedTotal = () => {
    const total = calculateTotal();
    const discountAmount = (total * discountPercentage) / 100;
    return total - discountAmount;
  };

  const formatCurrency = (value, symbol) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: symbol === "$" ? "USD" : "KES",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  return (
    <div className="w-full mx-auto p-6 flex flex-col items-center bg-white flex-1 md:ml-[75%] lg:ml-[50%]">
      <img
        src={Zola}
        alt="Zola Logo"
        className="w-24 h-24 object-cover rounded-full"
      />
      <h2 className="text-4xl font-normal text-gray-800 mt-4">
        Recommended Package
      </h2>
      <p className="text-xl mt-2 font-light text-gray-600 text-center">
        (We recommend providing one card for each customer-facing employee and
        one display per location.)
      </p>
      {industryType === "one_person" && (
        <>
          <div className="radio-inputs my-6">
            <label className="radio">
              <input
                type="radio"
                value="card"
                checked={selection === "card"}
                onChange={() => setSelection("card")}
              />
              <span className="name">Card</span>
            </label>
            <label className="radio">
              <input
                type="radio"
                value="stand"
                checked={selection === "stand"}
                onChange={() => setSelection("stand")}
              />
              <span className="name">Stand</span>
            </label>
            <label className="radio">
              <input
                type="radio"
                value="both"
                checked={selection === "both"}
                onChange={() => setSelection("both")}
              />
              <span className="name">Both</span>
            </label>
          </div>
          <div className="my-4">
            {selection === "card" && (
              <div className="p-4 bg-white rounded-lg shadow-md">
                <img
                  className="w-96 h-64 object-cover rounded-lg transition-transform transform hover:scale-105"
                  src={card}
                  alt="Card Image"
                />
                <div className="flex justify-between items-center mt-4">
                  <p className="text-lg font-medium text-gray-700">Price:</p>
                  <p className="text-lg font-semibold text-gray-900">
                    {formatCurrency(cardPrice, currencySymbol)}
                  </p>
                </div>
              </div>
            )}
            {selection === "stand" && (
              <div className="p-4 bg-white rounded-lg shadow-md">
                <img
                  className="w-96 h-64 object-cover rounded-lg transition-transform transform hover:scale-105"
                  src={stand}
                  alt="Stand Image"
                />
                <div className="flex justify-between items-center mt-4">
                  <p className="text-lg font-medium text-gray-700">Price:</p>
                  <p className="text-lg font-semibold text-gray-900">
                    {formatCurrency(standPrice, currencySymbol)}
                  </p>
                </div>
              </div>
            )}
            {selection === "both" && (
              <div className="flex space-x-4">
                <div className="p-4 bg-white rounded-lg shadow-md w-1/2">
                  <img
                    className="w-full h-64 object-cover rounded-lg transition-transform transform hover:scale-105"
                    src={card}
                    alt="Card Image"
                  />
                  <div className="flex justify-between items-center mt-4">
                    <p className="text-lg font-medium text-gray-700">Price:</p>
                    <p className="text-lg font-semibold text-gray-900">
                      {formatCurrency(cardPrice, currencySymbol)}
                    </p>
                  </div>
                </div>
                <div className="p-4 bg-white rounded-lg shadow-md w-1/2">
                  <img
                    className="w-full h-64 object-cover rounded-lg transition-transform transform hover:scale-105"
                    src={stand}
                    alt="Stand Image"
                  />
                  <div className="flex justify-between items-center mt-4">
                    <p className="text-lg font-medium text-gray-700">Price:</p>
                    <p className="text-lg font-semibold text-gray-900">
                      {formatCurrency(standPrice, currencySymbol)}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {industryType === "team" && (
        <div className="flex flex-col my-4">
          <div>
            <h3 className="font-normal text-xl">Zola Card(s)</h3>
            <span className="inline-block rounded-full py-1 text-sm font-light text-gray-700 mb-2">
              {currencySymbol} {formatCurrency(cardPrice, currencySymbol)} upfront per Card
            </span>
          </div>
          <div className="bg-black p-4 rounded-xl overflow-hidden shadow-lg">
            <img
              className="w-96 h-64 object-cover rounded-lg"
              src={card}
              alt="NFC Cards"
            />
            <div className="flex flex-col gap-0 my-4">
              <div className="flex justify-between text-white font-bold text-xl">
                <p>NFC Cards</p>
                <p>{formatCurrency(cardCount * cardPrice, currencySymbol)}</p>
              </div>
              <p className="text-gray-400 text-base">
                {formatCurrency(cardPrice, currencySymbol)} per Card
              </p>
            </div>
            <div className="flex items-center bg-black rounded-full overflow-hidden">
              <button
                className="px-4 py-2 text-white focus:outline-none hover:bg-gray-700"
                onClick={decrementCardCount}
                aria-label="Decrement card count"
              >
                -
              </button>
              <span className="px-4 py-2 text-lg font-semibold text-white">
                {cardCount}
              </span>
              <button
                className="px-4 py-2 text-white focus:outline-none hover:bg-gray-700"
                onClick={incrementCardCount}
                aria-label="Increment card count"
              >
                +
              </button>
            </div>
          </div>

          {includeZolaStand && (
            <div className="flex items-center gap-4 p-4 bg-white">
              <div className="flex items-center bg-black rounded-full overflow-hidden">
                <button
                  className="px-4 py-2 text-white focus:outline-none hover:bg-gray-700"
                  onClick={decrementStandCount}
                  aria-label="Decrement stand count"
                >
                  -
                </button>
                <span className="px-4 py-2 text-lg font-semibold text-white">
                  {standCount}
                </span>
                <button
                  className="px-4 py-2 text-white focus:outline-none hover:bg-gray-700"
                  onClick={incrementStandCount}
                  aria-label="Increment stand count"
                >
                  +
                </button>
              </div>

              <div>
                <h3 className="font-normal text-xl">Zola Stand(s)</h3>
                <span className="inline-block py-1 text-sm font-light text-gray-500">
                  {formatCurrency(standPrice, currencySymbol)} upfront per Stand
                </span>
              </div>
            </div>
          )}

          <div className="flex justify-center items-center mt-8">
            <input
              type="checkbox"
              className="mr-3 h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              checked={includeZolaStand}
              onChange={(e) => setIncludeZolaStand(e.target.checked)}
            />
            <label className="text-gray-800 text-sm">
              Include Zola NFC Stand
            </label>
          </div>
        </div>
      )}

      <div className="w-full mx-auto rounded-lg bg-white overflow-hidden shadow-lg border border-gray-300">
        <div className="px-6 py-5">
          <div className="font-bold text-2xl text-gray-900 mb-5 border-b border-gray-200 pb-3">
            Checkout
          </div>
          <div className="text-gray-800 mb-4">
            <div className="flex justify-between mb-2">
              <span className="text-sm">Your cart subtotal:</span>
              <span className="font-semibold">
                {formatCurrency(
                  calculateTotal() - Number(shippingPrice) - subscriptionAmount,
                  currencySymbol
                )}
              </span>
            </div>
            <div className="flex justify-between mb-4">
              <span className="text-sm">Shipping fees:</span>
              <span className="font-semibold">
                {formatCurrency(Number(shippingPrice), currencySymbol)}
              </span>
            </div>
            <div className="flex justify-between mb-4">
              <span className="text-sm">Subscription amount:</span>
              <span className="font-semibold">
                {formatCurrency(subscriptionAmount, currencySymbol)}
              </span>
            </div>
            <div className="flex justify-between mb-4">
              <span className="text-sm">Discount:</span>
              <span className="font-semibold text-red-600">
                {discountPercentage}% {/* Display discount percentage */}
              </span>
            </div>
          </div>
          <SubscriptionComponent
            setSubscriptionType={setSubscriptionType}
            setTransactionType={setTransactionType}
            subscriptionType={subscriptionType}
            transactionType={transactionType}
            currencySymbol={currencySymbol}
            monthlySubscription={monthSubscription}
            yearlySubscription={yearSubscription}
          />
        </div>
        <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
          <div className="flex justify-between items-center text-gray-900">
            <span className="text-lg font-semibold">Original Total:</span>
            <span className="text-2xl font-bold text-blue-900">
              <sup className="text-lg">{currencySymbol}</sup>
              {calculateTotal().toFixed(2)}
            </span>
          </div>
          <div className="flex justify-between items-center text-gray-900 mt-2">
            <span className="text-lg font-semibold">Total with Discount:</span>
            <span className="text-2xl font-bold text-blue-900">
              <sup className="text-lg">{currencySymbol}</sup>
              {calculateDiscountedTotal().toFixed(2)}
            </span>
          </div>
        </div>
        <div className="px-6 py-4 flex justify-between bg-gray-50 border-t border-gray-200">
          <button
            type="button"
            onClick={prevStep}
            aria-label="Go to the previous step"
            className="px-5 py-2 bg-gray-800 text-white text-sm font-medium rounded-md shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-800"
          >
            Back
          </button>
          <button
            type="button"
            onClick={handleCheckout}
            className="px-5 py-2 bg-blue-700 text-white text-sm font-medium rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-700"
            disabled={isCheckoutDisabled}
            aria-label="Proceed to checkout"
            aria-disabled={isCheckoutDisabled}
          >
            Checkout
          </button>
        </div>
      </div>
    </div>
  );
};

StepThree.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    industryType: PropTypes.string.isRequired,
    numberOfEmployees: PropTypes.number,
    numberOfCards: PropTypes.number,
  }).isRequired,
};

export default StepThree;
