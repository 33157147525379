import { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FaStar } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import useReviewsData from "../custom-hook/useReviewsData";
import { HiDotsHorizontal } from "react-icons/hi";
import { AiOutlineLink } from "react-icons/ai";
import { MdOutlineReviews, MdOutlineSentimentSatisfied } from "react-icons/md";
import { CiStar } from "react-icons/ci";
import { RiChatFollowUpLine } from "react-icons/ri";
import Taps from "./charts/Taps";
import {
  getLinkInteractions,
  listFeedbackWithoutParams,
  feedBackList,
} from "../../api";
import axios from "axios";
const ReviewsAnalytics = () => {
  const {
    reviews,
    rating,
    loading,
    visitorData,
    totalReviewsToday,
    totalReviews,
  } = useReviewsData();
  console.log(visitorData)
  const [linkInteractions, setLinkInteractions] = useState(null);
  const [feedbacks, setFeedbacks] = useState(0);
  const [weeklyFeedbacks, setWeeklyFeedbacks] = useState(0);
  const [monthlyFeedbacks, setMonthlyFeedbacks] = useState(0);
  // const [filterType, setFilterType] = useState('week');
  const [feedBacks, setFeedBacks] = useState("");
  const [currentWeekEmoji, setCurrentWeekEmoji] = useState("");
  const [previousWeekEmoji, setPreviousWeekEmoji] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [feedbackData, setFeedbackData] = useState(null);
  const [fBData, setFBData]  = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await feedBackList();
        console.log(data);
        setFeedbackData(data.categorized_feedbacks);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    // Initialize WebSocket connection
    const socket = new WebSocket(`wss://zola.technology/ws/feedback/list-filtered-feedback/real-time/?token=${token}`);

    // Connection opened
    socket.onopen = () => {
      console.log("WebSocket connection established");
    };

    // Listen for messages
    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);

      if (message.event === "list_filtered_feedback") {
        setFBData(message.feedback_data)
      }
    };

    // Handle WebSocket errors
    socket.onerror = (error) => {
      console.error("WebSocket error");
    };
console.log(fBData)
    // Handle WebSocket closure
    socket.onclose = (event) => {
      if (event.wasClean) {
        console.log("WebSocket connection closed cleanly");
      } else {
        console.error("WebSocket connection closed unexpectedly");
      }
    };

    // Cleanup the WebSocket connection on component unmount
    return () => {
      socket.close();
    };
  }, []);

  // Fetch link interactions data
  useEffect(() => {
    const fetchLinkInteractions = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }
        const data = await getLinkInteractions(token);
        console.log(data);
        setLinkInteractions(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchLinkInteractions();
  }, []);

  // Fetch weekly || monthly feedback data
  // Fetch weekly || monthly feedback data
  useEffect(() => {
    const fetchSortedFeedback = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }
        const response = await axios.get(
          "https://zola.technology/api/company/feedback/get-filtered-feedback/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              filter: "week", // Default filter type
            },
          }
        );

        const feedbackData = response.data;

        // Calculate the total feedback count
        const total = feedbackData.reduce(
          (sum, item) => sum + item.feedback.length,
          0
        );

        setWeeklyFeedbacks(total);
        console.log(`Total feedbacks for week:`, total);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchSortedFeedback();
  }, []);

  useEffect(() => {
    const fetchMonthlyFeedback = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }
        const response = await axios.get(
          "https://zola.technology/api/company/feedback/get-filtered-feedback/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              filter: "month",
            },
          }
        );

        const feedbackData = response.data;

        // Calculate the total feedback count
        const total = feedbackData.reduce(
          (sum, item) => sum + item.feedback.length,
          0
        );

        setMonthlyFeedbacks(total);
        console.log(`Total feedbacks for month:`, total);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchMonthlyFeedback();
  }, []);

  // Fetch feedback data
  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }
        const data = await listFeedbackWithoutParams(token);
        console.log(data);
        setFeedbacks(data.total || []);
        setFeedBacks(data.data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchFeedbacks();
  }, []);

  // Fetch feedback data
  useEffect(() => {
    const fetchFeedbacksData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }
        const data = await feedBackList(token);
        console.log(data);

        setFeedBacks(data);
        // Ensure that data.data is an array
        // const feedbackArray = Array.isArray(data.data) ? data.data : [];

        // setFeedbacks(data.total || 0);
        // setFeedBacks(feedbackArray);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchFeedbacksData();
  }, []);

  useEffect(() => {
    const fetchAverageFeedback = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }

        // Fetch data for both weeks
        const [currentWeekResponse, previousWeekResponse] = await Promise.all([
          axios.get(
            "https://zola.technology/api/company/feedback/get-average/",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                filter: "current_week",
              },
            }
          ),
          axios.get(
            "https://zola.technology/api/company/feedback/get-average/",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                filter: "previous_week",
              },
            }
          ),
        ]);

        // Extract and calculate average ratings
        const currentWeekRating = currentWeekResponse.data.averageRating || 0;
        const previousWeekRating = previousWeekResponse.data.averageRating || 0;

        // Set the sentiment emojis based on ratings
        const currentWeekEmoji = getSentimentEmoji(currentWeekRating);
        const previousWeekEmoji = getSentimentEmoji(previousWeekRating);

        // Set the states for the emojis (assuming you have these states)
        setCurrentWeekEmoji(currentWeekEmoji);
        setPreviousWeekEmoji(previousWeekEmoji);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchAverageFeedback();
  }, []);

  const overallRating = useMemo(() => {
    if (!Array.isArray(reviews) || reviews.length === 0) return 0;
    return (
      reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length
    );
  }, [reviews]);

  const zolaReviews = feedbacks;
  console.log(zolaReviews);

  const zolaFeedbacks = feedBacks;
  console.log(zolaFeedbacks);

  const ratings = rating;

  const calculatePercentageChange = (currentValue, previousValue) => {
    if (previousValue === 0) {
      return currentValue === 0 ? 0 : 100;
    }
    if (isNaN(currentValue) || isNaN(previousValue)) {
      return 0;
    }
    const difference = currentValue - previousValue;
    const percentageChange = (difference / previousValue) * 100;
    return percentageChange.toFixed(2);
  };

  const previousReviews = 0;
  const percentageChange = calculatePercentageChange(
    totalReviews,
    previousReviews
  );

  const getSentimentEmoji = (averageRating) => {
    if (averageRating >= 4.5) return "😊";
    if (averageRating >= 3) return "🙂";
    if (averageRating >= 2) return "😐";
    return "☹️";
  };

  // Calculate the number of taps and percentage change for link interactions
  const totalTaps = linkInteractions?.total_filtered_taps || 0;
  console.log(totalTaps);
  const previousTaps = 0; // Define a previous value if applicable
  const tapsPercentageChange = calculatePercentageChange(
    totalTaps,
    previousTaps
  );

  const totalVisitors = linkInteractions?.total_visitors || 0; // Assuming your data has total_visitors field
  const currentVisitors = linkInteractions?.current_visitors || 0; // Assuming your data has current_visitors field

  const negativeFeedback = fBData?.negative_feedback_count;
  // Array.isArray(feedBacks)
  // ? feedBacks.filter((review) => review.rating <= 2).length
  // : 0;
  console.log(negativeFeedback);

  const positiveFeedback = fBData?.positive_feedback_count;
  const categorizedFeedback = fBData?.categorized_feedbacks || 0;
  // Array.isArray(feedBacks)
  //   ? feedBacks.filter((review) => review.rating >= 3).length
  //   : 0;

  if (loading) {
    return (
      <section className="flex justify-center items-center h-full">
        <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
      </section>
    );
  }

  return (
    <div className="min-h-screen bg-white dark:bg-gradient-to-br dark:from-black dark:via-gray-900 dark:to-gray-800 text-black dark:text-white p-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <CountCard
          totalReviews={totalReviews}
          percentageChange={percentageChange}
          visitorData={visitorData}
          totalReviewsToday={totalReviewsToday}
        />
        <AverageSentimentCard
          currentWeekEmoji={currentWeekEmoji}
          previousWeekEmoji={previousWeekEmoji}
          className="md:col-span-2 lg:col-span-1"
        />
        <AverageRatingCard
          overallRating={overallRating}
          totalReviews={totalReviews}
          ratings={ratings}
          zolaReviews={zolaReviews}
          calculateReviewsPercentageChange={calculatePercentageChange}
        />
        <LinkInteractionsCard
          totalTaps={totalTaps}
          tapsPercentageChange={tapsPercentageChange}
          totalVisitors={totalVisitors}
          currentVisitors={currentVisitors}
        />
        <PositiveFeedbackCard positiveFeedback={positiveFeedback} />
        <NegativeFeedbackCard negativeFeedback={negativeFeedback} />
        <FollowUpsCard
          feedbackData={categorizedFeedback}
          className="md:col-span-1 lg:col-span-2"
        />
        <WeeklyFeedbackCard
          weeklyFeedbacks={weeklyFeedbacks}
          monthlyFeedbacks={monthlyFeedbacks}
        />
        {/* <Taps className="md:col-span-1 lg:col-span-3" /> */}
      </div>
    </div>
  );
};

const Card = ({ title, children, className, icon }) => {
  return (
    <div
      className={`bg-white dark:bg-gray-800 dark:bg-opacity-80 dark:backdrop-filter dark:backdrop-blur-lg p-6 rounded-xl shadow-lg transition-transform transform hover:scale-105 ${className}`}
    >
      <header className="flex items-start justify-between">
        <div className="flex gap-1 m-0 p-0">
          <span className="text-2xl text-black dark:text-white">{icon}</span>
          <h2 className="text-2xl font-semibold mb-4 text-black dark:text-white">
            {title}
          </h2>
        </div>
        {/* <HiDotsHorizontal className="w-6 h-6 text-black dark:text-white" /> */}
      </header>
      {children}
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
};

const WeeklyFeedbackCard = ({ weeklyFeedbacks, monthlyFeedbacks }) => {
  const [filterType, setFilterType] = useState("week");

  const handleFilterChange = (type) => {
    setFilterType(type);
  };

  const feedbackCount =
    filterType === "week" ? weeklyFeedbacks : monthlyFeedbacks;

  return (
    <Card className="bg-white dark:bg-gray-800 dark:bg-opacity-80 dark:backdrop-filter dark:backdrop-blur-lg border border-gray-200 dark:border-gray-700 rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-white">
        Feedback Statistics
      </h2>
      <div className="flex gap-4 mb-6">
        <button
          aria-label="Filter by week"
          onClick={() => handleFilterChange("week")}
          className={`py-2 px-6 rounded-lg text-lg font-semibold transition-transform transform ${
            filterType === "week"
              ? "bg-gradient-to-r from-blue-400 to-blue-600 text-white shadow-lg"
              : "bg-gray-200 text-gray-700 hover:bg-gray-300"
          }`}
        >
          Weekly
        </button>
        <button
          aria-label="Filter by month"
          onClick={() => handleFilterChange("month")}
          className={`py-2 px-6 rounded-lg text-lg font-semibold transition-transform transform ${
            filterType === "month"
              ? "bg-gradient-to-r from-blue-400 to-blue-600 text-white shadow-lg"
              : "bg-gray-200 text-gray-700 hover:bg-gray-300"
          }`}
        >
          Monthly
        </button>
      </div>
      <div className="flex items-center gap-4">
        <MdOutlineSentimentSatisfied className="text-4xl text-green-500" />
        <p className="text-xl font-medium text-gray-800 dark:text-white">
          {filterType.charAt(0).toUpperCase() + filterType.slice(1)} Feedbacks:{" "}
          {feedbackCount}
        </p>
      </div>
    </Card>
  );
};

WeeklyFeedbackCard.propTypes = {
  weeklyFeedbacks: PropTypes.number.isRequired,
  monthlyFeedbacks: PropTypes.number.isRequired,
  className: PropTypes.string,
};

const AverageSentimentCard = ({
  currentWeekEmoji,
  previousWeekEmoji,
  className,
}) => {
  return (
    <Card
      title="Average Sentiment"
      icon={<MdOutlineSentimentSatisfied />}
      className={`${className}`}
    >
      <div className="flex flex-col items-center mt-4 space-y-2">
        {/* Current Week Emoji */}
        <span className="text-8xl text-yellow-400 mb-2">
          {currentWeekEmoji}
        </span>

        {/* Conditionally Render Previous Week Emoji with a Note */}
        {previousWeekEmoji && (
          <div className="flex items-center space-x-2 text-gray-600">
            <span className="text-3xl">{previousWeekEmoji}</span>
            <span className="text-sm italic">Last Week</span>
          </div>
        )}
      </div>
    </Card>
  );
};

AverageSentimentCard.propTypes = {
  currentWeekEmoji: PropTypes.string.isRequired,
  previousWeekEmoji: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const AverageRatingCard = ({
  totalReviews,
  ratings,
  zolaReviews,
  calculateReviewsPercentageChange,
  className,
}) => {
  // Ensure valid inputs
  const validTotalReviews = typeof totalReviews === "number" ? totalReviews : 0;
  const reviewsPercentageChange = calculateReviewsPercentageChange(
    validTotalReviews,
    0
  ); // Replace 0 with a previous value if applicable

  return (
    <Card title="Average Rating" icon={<CiStar />} className={className}>
      <div className="flex gap-3 justify-start items-center">
        <span className="text-5xl font-bold text-black dark:text-white">
          {ratings}
        </span>
        <span
          className={`py-1 px-2 rounded-lg text-md ${
            reviewsPercentageChange >= 0
              ? "bg-green-100 text-green-400 dark:bg-gray-700"
              : "bg-red-100 text-red-400 dark:bg-gray-700"
          }`}
        >
          {reviewsPercentageChange}%
        </span>
      </div>
      <div className="flex flex-col justify-around w-full mt-4 text-lg text-black dark:text-white gap-2">
        <div className="flex items-center justify-between gap-2">
          <span className="flex justify-center items-center gap-2">
            <FcGoogle className="w-5 h-5" />
            Google
          </span>
          {totalReviews}
        </div>
        <div className="flex items-center justify-between gap-2">
          <span className="flex justify-center items-center gap-2">
            <FaStar className="text-yellow-400 w-5 h-5" />
            Zola Reviews
          </span>
          {zolaReviews}
        </div>
      </div>
    </Card>
  );
};

AverageRatingCard.propTypes = {
  overallRating: PropTypes.number.isRequired,
  totalReviews: PropTypes.number.isRequired,
  zolaReviews: PropTypes.number.isRequired,
  ratings: PropTypes.number.isRequired,
  calculateReviewsPercentageChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const LinkInteractionsCard = ({
  totalTaps,
  tapsPercentageChange,
  totalVisitors,
  currentVisitors,
  className,
}) => {
  // Calculate percentage of current visitors safely
  const visitorsPercentage =
    totalVisitors !== 0 ? (currentVisitors / totalVisitors) * 100 : 0;
  return (
    <Card
      title="Link Engagements"
      icon={<AiOutlineLink />}
      className={className}
    >
      <div className="flex justify-between items-start gap-2">
        <div className="flex gap-3 justify-center items-center">
          <span className="text-5xl font-bold text-black dark:text-white">
            {totalTaps}
          </span>
          <span
            className={`py-1 px-2 rounded-lg text-md ${
              tapsPercentageChange >= 0
                ? "bg-green-100 text-green-400 dark:bg-gray-700"
                : "bg-red-100 text-red-400 dark:bg-gray-700"
            }`}
          >
            {tapsPercentageChange}%
          </span>
        </div>
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-400 mt-4">
        Data obtained for the last 1 year from{" "}
        <span className="text-black dark:text-white">{totalVisitors}</span>{" "}
        visitors to{" "}
        <span className="text-black dark:text-white">{totalTaps}</span>{" "}
        visitors.
      </p>
      <div className="flex items-center gap-1">
        <div className="w-[70%] bg-gray-300 dark:bg-gray-700 rounded-full h-1 mt-4">
          <div className="bg-black dark:bg-white h-1 rounded-full w-[100%]">
            <div
              className="bg-green-500 h-1 rounded-full"
              style={{ width: `${visitorsPercentage}%` }} // Adjust width based on visitorsPercentage
            ></div>
          </div>
        </div>
        <p className="text-black dark:text-white text-sm mt-2">
          {totalTaps} this year
        </p>
      </div>
    </Card>
  );
};

LinkInteractionsCard.propTypes = {
  totalTaps: PropTypes.number.isRequired,
  tapsPercentageChange: PropTypes.number.isRequired,
  totalVisitors: PropTypes.number.isRequired,
  currentVisitors: PropTypes.number.isRequired,
  className: PropTypes.string,
};

const FollowUpsCard = ({ className, feedbackData }) => {
  const {
    rating_of_1 = [],
    rating_of_2 = [],
    rating_of_3 = [],
  } = feedbackData || {};

  // Filter feedbacks with resolved status as false
  const unresolvedRating1 = rating_of_1.filter(
    (feedback) => !feedback.resolved
  );
  const unresolvedRating2 = rating_of_2.filter(
    (feedback) => !feedback.resolved
  );
  const unresolvedRating3 = rating_of_3.filter(
    (feedback) => !feedback.resolved
  );

  // Check if there are no follow-up data for 1, 2, and 3-star ratings
  const noFollowUpData =
    unresolvedRating1.length === 0 &&
    unresolvedRating2.length === 0 &&
    unresolvedRating3.length === 0;

  return (
    <Card
      title="Follow-ups"
      icon={<RiChatFollowUpLine />}
      className={className}
    >
      <div className="flex flex-col gap-4">
        {noFollowUpData ? (
          <p className="text-black dark:text-white">
            No follow-up data available.
          </p>
        ) : (
          <>
            {unresolvedRating1.length > 0 && (
              <p className="text-black dark:text-white">
                You have {unresolvedRating1.length} feedbacks with 1-star rating
                that needs follow-up.
              </p>
            )}
            {unresolvedRating2.length > 0 && (
              <p className="text-black dark:text-white">
                You have {unresolvedRating2.length} feedbacks with 2-star rating
                that needs follow-up.
              </p>
            )}
            {unresolvedRating3.length > 0 && (
              <p className="text-black dark:text-white">
                You have {unresolvedRating3.length} feedbacks with 3-star rating
                that needs follow-up.
              </p>
            )}
          </>
        )}
      </div>
    </Card>
  );
};

FollowUpsCard.propTypes = {
  className: PropTypes.string,
  feedbackData: PropTypes.shape({
    rating_of_1: PropTypes.arrayOf(
      PropTypes.shape({
        resolved: PropTypes.bool,
      })
    ),
    rating_of_2: PropTypes.arrayOf(
      PropTypes.shape({
        resolved: PropTypes.bool,
      })
    ),
    rating_of_3: PropTypes.arrayOf(
      PropTypes.shape({
        resolved: PropTypes.bool,
      })
    ),
  }),
};

const CountCard = ({
  totalReviews,
  visitorData,
  totalReviewsToday,
  percentageChange,
}) => {
  // Ensure `totalReviewsToday` is a valid number
  const adjustedPercentageChange = Number.isFinite(totalReviewsToday)
    ? totalReviewsToday
    : 0;
  // const adjustedPercentageChange = 50;

  // Ensure the width percentage is within 0 to 100%
  const barWidth = Math.min(Math.abs(adjustedPercentageChange), 100);
  console.log(`Barwidth value is: ${barWidth}`);
  console.log(`totalReviewsToday value is: ${totalReviewsToday}`);
  console.log(`adjustedPercentageChange value is: ${adjustedPercentageChange}`);

  return (
    <Card title="Total Reviews" icon={<MdOutlineReviews />}>
      <div className="flex gap-2 items-center">
        <span className="text-5xl font-bold text-black dark:text-white">
          {totalReviews}
        </span>
        <span
          className={`py-1 px-2 rounded-lg text-md ${
            percentageChange >= 0
              ? "bg-green-100 text-green-400 dark:bg-gray-700"
              : "bg-red-100 text-red-400 dark:bg-gray-700"
          }`}
        >
          {percentageChange}%
        </span>
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-400 mt-4">
        Visitor data obtained for the last 7 days from{" "}
        <span className="text-black dark:text-white">{visitorData.start}%</span>{" "}
        to{" "}
        <span className="text-black dark:text-white">{visitorData.end}%</span>.
      </p>
      <div className="flex items-center gap-1">
        <div className="w-[70%] bg-gray-300 dark:bg-gray-700 rounded-full h-1 mt-4">
          <div className="bg-black dark:bg-white h-1 rounded-full w-[100%]">
            <div
              className="bg-green-500 h-1 rounded-full"
              style={{ width: `${barWidth}%` }}
            ></div>
          </div>
        </div>
        <p className="text-black dark:text-white text-sm mt-2">
          {totalReviewsToday} today
        </p>
      </div>
    </Card>
  );
};

CountCard.propTypes = {
  totalReviews: PropTypes.number.isRequired,
  totalReviewsToday: PropTypes.number.isRequired,
  visitorData: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }).isRequired,
  percentageChange: PropTypes.number.isRequired,
};

// Positive Feedback Card Component
const PositiveFeedbackCard = ({ positiveFeedback }) => {
  return (
    <Card title="Positive Feedback" icon={<MdOutlineSentimentSatisfied />}>
      <div className="flex flex-col items-center mt-4">
        <span className="text-5xl font-bold text-green-400">
          {positiveFeedback}
        </span>
        <p className="text-lg text-black dark:text-white">reviews</p>
      </div>
    </Card>
  );
};

PositiveFeedbackCard.propTypes = {
  positiveFeedback: PropTypes.number.isRequired,
};

// Negative Feedback Card Component
const NegativeFeedbackCard = ({ negativeFeedback }) => {
  return (
    <Card title="Negative Feedback" icon={<MdOutlineSentimentSatisfied />}>
      <div className="flex flex-col items-center mt-4">
        <span className="text-5xl font-bold text-red-400">
          {negativeFeedback}
        </span>
        <p className="text-lg text-black dark:text-white">reviews</p>
      </div>
    </Card>
  );
};

NegativeFeedbackCard.propTypes = {
  negativeFeedback: PropTypes.number.isRequired,
};

export default ReviewsAnalytics;
