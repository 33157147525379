import { motion } from "framer-motion";
import Slider from "react-slick";
import ganesh from "./ganesh.jpeg";
import fedup from "./fedup.jpeg";
import printmark from "./printmark.jpeg";
import swthz from "./swthz.jpeg";
import total from "./total.jpeg";
import dancom from "./dancom.png";
import mentorspace from "./mentorspace.png";
import mocha from "./mocha.png";
import serena from "./serena.png";

const logos = [
  ganesh,
  fedup,
  printmark,
  swthz,
  total,
  dancom,
  mentorspace,
  mocha,
  serena,
];

function LogoCarousel() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    cssEase: "linear",
    pauseOnHover: false,
    autoplaySpeed: 0,
    speed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const logoVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: { scale: 1, opacity: 1, transition: { duration: 0.5 } },
  };

  return (
    <div className="w-full max-w-[1340px] mx-auto mt-8 py-10 rounded-3xl">
      <h3 className="text-center text-2xl sm:text-3xl md:text-4xl lg:text-[38px] font-semibold mb-6 md:mb-8 leading-snug md:leading-[49px]">Trusted by</h3>
      <Slider {...settings} className="w-3/4 mx-auto">
        {logos.map((logo, index) => (
          <motion.div
            key={index}
            className="flex justify-center items-center p-2"
            variants={logoVariants}
            initial="hidden"
            animate="visible"
          >
            <img
              src={logo}
              alt={`Logo ${index + 1}`}
              className="w-48 h-48 object-contain grayscale opacity-75"
              loading="lazy"
            />
          </motion.div>
        ))}
      </Slider>
    </div>
  );
}

export default LogoCarousel;
