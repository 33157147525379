import { useState, useContext, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoNotifications } from "react-icons/io5";
import { FaBars } from "react-icons/fa";
import ZolaLogo from "../../HQDashboard/components/sidebar/Layer_1.png";
import { AuthContext } from "../../context/AuthContext";

const Topbar = ({ toggleSidebar, theme, toggleTheme }) => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { currentUser, isAuthenticated, logout } = useContext(AuthContext);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const profileImage = userData?.profile_image;
  const isHq = userData?.is_hq_company;
  const navigate = useNavigate();
  const notificationRef = useRef();

  const handleNotificationToggle = () => {
    setIsNotificationOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      setIsNotificationOpen(false);
    }
  };

  useEffect(() => {
    if (isNotificationOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNotificationOpen]);

  const handleLogout = () => {
    logout();
  };

  const handleClick = () => {
    navigate("/hqdashboard");
  };

  if (!currentUser || !isAuthenticated) {
    return null;
  }

  const notificationsData = [
    // {
    //   id: 1,
    //   icon: "📬",
    //   bgColor: "bg-blue-100 dark:bg-blue-600",
    //   textColor: "text-blue-600 dark:text-blue-100",
    //   message: "New message from admin",
    //   time: "5 minutes ago",
    // },
    // {
    //   id: 2,
    //   icon: "✅",
    //   bgColor: "bg-green-100 dark:bg-green-600",
    //   textColor: "text-green-600 dark:text-green-100",
    //   message: "Task completed successfully",
    //   time: "2 hours ago",
    // },
    // {
    //   id: 3,
    //   icon: "📢",
    //   bgColor: "bg-yellow-100 dark:bg-yellow-600",
    //   textColor: "text-yellow-600 dark:text-yellow-100",
    //   message: "System maintenance scheduled",
    //   time: "Yesterday at 10:00 PM",
    // },
  ];

  return (
    <div className="flex items-center justify-between p-4 bg-white dark:bg-gray-900 shadow-md dark:shadow-lg border-b-[1px] border-[#EAEAEC] lg:w-full xs:w-full">
      {/* Logo */}
      <div className="md:flex lg:hidden items-center space-x-4">
        <img
          src={ZolaLogo}
          alt="Company Logo"
          className="w-24 h-10 object-contain"
        />
      </div>

      {/* User Greeting */}
      <div className="hidden lg:flex items-center space-x-2 mx-4">
        <div className="flex items-center space-x-2">
          <img
            src={profileImage}
            alt="User Avatar"
            className="w-10 h-10 rounded-full object-cover border-[2px] border-[#0C4EF8]"
          />
          <div>
            <h2 className="text-lg font-normal uppercase dark:text-white">
              {userData.company_name}
            </h2>
          </div>
        </div>
      </div>

      {/* Notifications & HQ Switch */}
      <div className="flex items-center space-x-2">
        <button
          aria-label="Toggle Notifications"
          onClick={handleNotificationToggle}
          className="p-2 text-gray-700 dark:text-gray-300 hidden sm:block"
        >
          <IoNotifications className="text-[#0C4EF8] h-5 w-5" />
        </button>

        {/* Notification Dropdown */}
        {isNotificationOpen && (
        <div
          ref={notificationRef}
          className="absolute top-12 right-1 w-80 bg-white dark:bg-gray-800 shadow-lg rounded-lg border border-gray-200 dark:border-gray-700 z-50"
        >
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <h3 className="text-base font-semibold text-gray-700 dark:text-gray-300">
              Notifications
            </h3>
          </div>
          {notificationsData.length > 0 ? (
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
              {notificationsData.map((notification) => (
                <li
                  key={notification.id}
                  className="p-4 flex items-start space-x-3 hover:bg-gray-100 dark:hover:bg-gray-700 transition"
                >
                  <div
                    className={`flex items-center justify-center w-8 h-8 ${notification.bgColor} ${notification.textColor} rounded-full`}
                  >
                    {notification.icon}
                  </div>
                  <div>
                    <p className="text-sm text-gray-700 dark:text-gray-300 font-medium">
                      {notification.message}
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                      {notification.time}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="p-4 text-center text-gray-500 dark:text-gray-400">
              <p>No notifications at the moment! 🎉</p>
            </div>
          )}
        </div>
      )}

        <button
          aria-label="Toggle Sidebar"
          className="md:hidden p-2 text-gray-700 dark:text-gray-300"
          onClick={toggleSidebar}
        >
          <FaBars className="h-5 w-4" />
        </button>

        {isHq && (
          <button
            aria-label="Switch to HQ Dashboard"
            className="hidden sm:block px-4 py-2 bg-blue-600 text-white rounded-[12px] hover:bg-blue-700 transition shadow-lg dark:bg-blue-500 dark:hover:bg-blue-600"
            onClick={handleClick}
          >
            Switch HQ Dashboard
          </button>
        )}
      </div>
    </div>
  );
};

export default Topbar;
