import PropTypes from 'prop-types';
import '../stylings/header.css'
import { Link } from 'react-router-dom';

import { GoArrowLeft } from "react-icons/go";
const DashboardHeader = ({ title }) => {
  return (<div className='flex  items-center text-[#454861]'>
    <Link to="/dashboard" className="flex items-center text-[#282A3E] dark:text-white">
      <GoArrowLeft  className="w-12 h-6 p-0 "/>
      </Link>

    <h1 className="text-[24px] font-medium dark:text-white ">{title}</h1>
  </div>);
};

DashboardHeader.propTypes = {
  title: PropTypes.string.isRequired,
}

export default DashboardHeader