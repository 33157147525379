import { useState, useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";
import { IoMdArrowDropup } from "react-icons/io";
import ReactApexChart from "react-apexcharts";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const AreaGraph = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [totalReviews, setTotalReviews] = useState(0);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }

        const response = await fetch(
          `https://zola.technology/api/company/employees/performance/filter/get-filtered-taps/?filter=year&year=${selectedYear}`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        console.log(data);

        if (data && data.periods) {
          setReviews(data.periods); // Update reviews with the periods data
          setTotalReviews(data.total_filtered_taps); // Set total filtered taps
        } else {
          throw new Error("Invalid data format");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedYear]);
// {
//   // Modify the data variable for the chart
//   const data = useMemo(() => {
//     const counts = reviews.map((period) => period.taps);

//     return {
//       labels: reviews.map((period) => period.period.split(" ")[0]), // Extract month name from period
//       datasets: [
//         {
//           label: "Reviews (%)",
//           data: counts.map((count) =>
//             totalReviews ? (count / totalReviews) * 100 : 0
//           ),
//           borderColor: "#34D399",
//           pointRadius: 5,
//           pointBackgroundColor: "#34D399",
//           tension: 0.3,
//           fill: true,
//           backgroundColor: (context) => {
//             const chart = context.chart;
//             const { ctx, chartArea } = chart;

//             if (!chartArea) {
//               return null;
//             }

//             const gradient = ctx.createLinearGradient(
//               0,
//               chartArea.top,
//               0,
//               chartArea.bottom
//             );
//             gradient.addColorStop(0, "rgba(52, 211, 153, 0.5)");
//             gradient.addColorStop(1, "rgba(52, 211, 153, 0.1)");
//             return gradient;
//           },
//         },
//       ],
//     };
//   }, [reviews, totalReviews]);

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     plugins: {
//       legend: { display: false },
//       tooltip: {
//         callbacks: {
//           label: (context) => `${context.raw.toFixed(2)}%`,
//         },
//       },
//     },
//     scales: {
//       x: {
//         grid: { display: false },
//       },
//       y: {
//         beginAtZero: true,
//         max: 100,
//         ticks: { callback: (value) => `${value}%` },
//         grid: { display: false },
//       },
//     },
//   };}

//   // Calculate the chart data
 const chartData = useMemo(() => {
  const counts = reviews.map((period) => period.taps);

  return {
    series: [
      {
        name: "Reviews (%)",
        data: counts.map((count) =>
          totalReviews ? (count / totalReviews) * 100 : 0
        ),
      },
    ],
    options: {
      chart: {
        type: 'area',
        height: 300,
        toolbar: {
          show: false,
        },
        dataLabels: {
          enabled: false, 
        },  
       
      },
      stroke: {
        curve: 'straight',
        width: 2
      },
      markers: {
        size: 0,
        hover: { size: 0 } 
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: reviews.map((period) => period.period.split(" ")[0]), // Extract month name from period
        labels: {
          style: {
            colors: '#333',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#333',
          },
          formatter: (value) => `${value}%`,
        },
        max: 100,
      },
    
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.3,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      colors: ["#34D399"],
      tooltip: {
        y: {
          formatter: (val) => `${val.toFixed(2)}%`,
        },
      },
      grid: {
        show: false,
      },
    },
  };
}, [reviews, totalReviews]);


  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <div className="flex  text-[#292D32] items-center">
          <div>
            <h3 className="text-2xl font-semibold  dark:text-white">
              Most Taps
            </h3>
            <span className="dark:text-gray-300">
              Active Cards
            </span>
          </div>
          <div className="flex items-center gap-2">
            <IoMdArrowDropup className="text-xl text-green-500" />
            <span className="text-xl font-medium text-green-500">25%</span>
          </div>
        </div>
        <div className="flex items-center gap-4">
          {/* <button
            onClick={() => setSelectedYear(selectedYear - 1)}
            className="bg-gray-700 text-white rounded px-4 py-2 text-sm hover:bg-gray-600 transition-colors dark:bg-gray-600 dark:hover:bg-gray-500"
          >
            Previous
          </button> */}
          <span className="text-lg text-gray-800 dark:text-white">
            {/* Year: {selectedYear} */}
            Year: 2024
          </span>
          {/* <button
            onClick={() => setSelectedYear(selectedYear + 1)}
            className="bg-gray-700 text-white rounded px-4 py-2 text-sm hover:bg-gray-600 transition-colors dark:bg-gray-600 dark:hover:bg-gray-500"
          >
            Next
          </button> */}
        </div>
      </div>

   {/* {   <div className="w-full h-[300px]">
        <Line data={data} options={options} />
      </div>} */}

      <div className="w-full h-[300px]">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="area"
          height={300}
        />
      </div>
    </div>
  );
};

export default AreaGraph;
