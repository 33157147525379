import { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import { CiCreditCard1 } from "react-icons/ci";

const cardStyle = {
  base: {
    color: "#32325d",
    fontFamily: "Arial, sans-serif",
    fontSize: "20px",
    "::placeholder": {
      color: "#a0aec0",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
    borderColor: "#fa755a",
  },
};

const PaymentDetailsForm = ({ onPaymentMethodReceived }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [zipCode, setZipCode] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");

    if (!stripe || !elements) {
      setError("Please try again later.");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        address: {
          postal_code: zipCode,
        },
      },
    });

    if (error) {
      setError(error.message);
      setLoading(false);
    } else {
      onPaymentMethodReceived(paymentMethod.id);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-2">
      {/* Title */}
      <h3 className="text-2xl font-normal mb-4 text-gray-900 dark:text-gray-100 flex items-center">
        Payment Method
      </h3>

      <p className="flex items-center mb-3 text-xl gap-1">
        <CiCreditCard1 className="text-blue-400 text-3xl" />
        Card
      </p>

      <div className="flex flex-col gap-6">
        {/* Card Number Field */}
        <label className="flex flex-col gap-1">
          <span className="text-gray-700 dark:text-gray-300">Card number</span>
          <div className="border border-gray-300 rounded-lg p-3 flex items-center dark:text-gray-300 dark:bg-gray-700 shadow-sm">
            <CardNumberElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#1A202C",
                    "::placeholder": {
                      color: "#A0AEC0",
                    },
                  },
                },
                placeholder: "1234 1234 1234 1234",
              }}
              className="flex-grow"
            />
            <svg
              width="34"
              height="24"
              viewBox="0 0 34 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2"
            >
              <rect
                x="0.5"
                y="0.5"
                width="33"
                height="23"
                rx="3.5"
                fill="white"
              />
              <rect
                x="0.5"
                y="0.5"
                width="33"
                height="23"
                rx="3.5"
                stroke="#F2F4F7"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.179 16.8295C15.9949 17.8275 14.459 18.43 12.7807 18.43C9.03582 18.43 6 15.4303 6 11.73C6 8.02972 9.03582 5.03003 12.7807 5.03003C14.459 5.03003 15.9949 5.63253 17.179 6.63057C18.363 5.63253 19.8989 5.03003 21.5773 5.03003C25.3221 5.03003 28.358 8.02972 28.358 11.73C28.358 15.4303 25.3221 18.43 21.5773 18.43C19.8989 18.43 18.363 17.8275 17.179 16.8295Z"
                fill="#ED0006"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.179 16.8295C18.6369 15.6006 19.5614 13.772 19.5614 11.73C19.5614 9.68807 18.6369 7.85947 17.179 6.63057C18.363 5.63253 19.8989 5.03003 21.5772 5.03003C25.3221 5.03003 28.3579 8.02972 28.3579 11.73C28.3579 15.4303 25.3221 18.43 21.5772 18.43C19.8989 18.43 18.363 17.8275 17.179 16.8295Z"
                fill="#F9A000"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.179 16.8295C18.6369 15.6006 19.5614 13.772 19.5614 11.7301C19.5614 9.68811 18.6369 7.85952 17.179 6.63062C15.7211 7.85952 14.7966 9.68811 14.7966 11.7301C14.7966 13.772 15.7211 15.6006 17.179 16.8295Z"
                fill="#FF5E00"
              />
            </svg>
          </div>
        </label>

        {/* Expiry Date and CVC Fields */}
        <div className="flex gap-4">
          <div className="flex-1">
            <label className="flex flex-col gap-1">
              <span className="text-gray-700 dark:text-gray-300">
                Expiration
              </span>
              <div className="border border-gray-300 rounded-md p-3 dark:bg-gray-700">
                <CardExpiryElement options={{ style: cardStyle }} />
              </div>
            </label>
          </div>
          <div className="flex-1">
            <label className="flex flex-col gap-1">
              <span className="text-gray-700 dark:text-gray-300">CVC</span>
              <div className="border border-gray-300 rounded-md p-3 dark:bg-gray-700">
                <CardCvcElement options={{ style: cardStyle }} />
              </div>
            </label>
          </div>
        </div>
         {/* Optional Zip Code Field */}
         <div className="flex-1">
            <label className="flex flex-col gap-1">
              <span className="text-gray-700 dark:text-gray-300">ZIP Code</span>
              <input
                type="text"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                placeholder="ZIP Code"
                className="border border-gray-300 rounded-md p-3 dark:bg-gray-700 dark:text-gray-300"
              />
            </label>
          </div>
      </div>

      {/* Submit Button */}
      <button
        aria-label="Submit Payment Details"
        aria-disabled={!stripe || loading}
        type="submit"
        disabled={!stripe || loading}
        className={`w-full bg-blue-600 text-white font-semibold py-3 mt-6 rounded-md hover:bg-blue-700 transition-colors ${
          loading ? "cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        {loading ? "Processing..." : "Submit Details"}
      </button>
    </form>
  );
};

PaymentDetailsForm.propTypes = {
  onPaymentMethodReceived: PropTypes.func.isRequired,
};

export default PaymentDetailsForm;
