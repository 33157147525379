import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
// import EditForm from "./EditForm";
import { FiCopy } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DataTable = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 5; // Items per page

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/get-all/?page=${currentPage}&page_size=${pageSize}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      const result = await response.json();
      setData(result.data);
      setTotalPages(result.total_pages);
    } catch (error) {
      setError(error.message);
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = (index) => {
    setSelectedItemIndex(index);
    setShowPopup(true);
  };

  const handleEditFormClose = () => setShowPopup(false);

  const handleUpdateData = async (updatedValues) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }

    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/update/${data[selectedItemIndex].id}/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: updatedValues.name,
            username: updatedValues.username,
          }),
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      const updatedData = [...data];
      updatedData[selectedItemIndex] = {
        ...updatedData[selectedItemIndex],
        name: updatedValues.name,
        username: updatedValues.username,
      };
      setData(updatedData);
      toast.success("Employee data updated successfully!");
    } catch (error) {
      setError(error.message);
      toast.error(`Update Error: ${error.message}`);
    }
  };

  const handleDelete = async (index) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }

    setLoading(true);
    setDeletingIndex(index);
    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/delete/${data[index].id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      setData(data.filter((_, i) => i !== index));
      toast.success("Employee deleted successfully!");
    } catch (error) {
      setError(error.message);
      toast.error(`Delete Error: ${error.message}`);
    } finally {
      setLoading(false);
      setDeletingIndex(null);
    }
  };

  const filteredData = data.filter((item) =>
    item.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url);
    toast.success("URL copied to clipboard!"); // Use toast instead of alert
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
      <ToastContainer position="top-right" autoClose={3000} />{" "}
      {/* Toast Container */}
      <div className="flex items-center mb-4">
        <input
          type="text"
          placeholder="Search by username"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-2 border border-gray-600 dark:border-gray-700 rounded-md bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
        />
        <span className="ml-2 text-gray-500 dark:text-gray-400">
          <FaSearch />
        </span>
      </div>
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
        <thead className="bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
              Full Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
              Username
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
              NFC Card URL
            </th>
            <th className="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-gray-800 text-gray-900 dark:text-white divide-y divide-gray-200 dark:divide-gray-700">
          {filteredData.map((item, index) => (
            <tr key={index}>
              <td className="px-6 py-4">{item.name}</td>
              <td className="px-6 py-4">{item.username || "-"}</td>
              <td className="px-6 py-4 flex items-center">
                <span className="mr-2">{item.employee_url || "-"}</span>
                {item.employee_url && (
                  <button
                    onClick={() => copyToClipboard(item.employee_url)}
                    style={{
                      border: "none",
                      background: "transparent",
                      cursor: "pointer",
                    }}
                    title="Copy URL"
                    aria-label="Copy employee URL"
                    role="button"
                    tabIndex="0"
                  >
                    <FiCopy className="text-blue-600 dark:text-blue-400" />
                  </button>
                )}
              </td>
              <td className="px-6 py-4 text-right">
                <button
                  className="mr-2 px-4 py-2 rounded-md bg-black text-white hover:bg-gray-800 dark:bg-gray-600 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
                  onClick={() => handleUpdate(index)}
                  aria-label="Update item"
                  role="button"
                  tabIndex="0"
                >
                  Update
                </button>

                <button
                  className={`px-4 py-2 rounded-md border border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white dark:border-blue-500 dark:text-blue-500 dark:hover:bg-blue-500 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 ${
                    loading && deletingIndex === index
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  onClick={() => handleDelete(index)}
                  disabled={loading && deletingIndex === index}
                  aria-label={
                    loading && deletingIndex === index
                      ? "Deleting item..."
                      : "Delete item"
                  }
                  role="button"
                  tabIndex="0"
                >
                  {loading && deletingIndex === index
                    ? "Deleting..."
                    : "Delete"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-4">
        <button
          className="px-4 py-2 rounded-md border border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white dark:border-blue-500 dark:text-blue-500 dark:hover:bg-blue-500 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          aria-label="Go to previous page"
          role="button"
          tabIndex="0"
        >
          Previous
        </button>

        <span
          className="px-4 py-2 text-gray-900 dark:text-white"
          aria-live="polite"
        >
          Page {currentPage} of {totalPages}
        </span>

        <button
          className="px-4 py-2 rounded-md border border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white dark:border-blue-500 dark:text-blue-500 dark:hover:bg-blue-500 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          aria-label="Go to next page"
          role="button"
          tabIndex="0"
        >
          Next
        </button>
      </div>
      {showPopup && selectedItemIndex !== null && data[selectedItemIndex] ? (
        <EditForm
          data={data}
          selectedItemIndex={selectedItemIndex}
          onUpdate={handleUpdateData}
          onClose={handleEditFormClose}
        />
      ) : null}
    </div>
  );
};

export default DataTable;
