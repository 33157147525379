import React from 'react'

const Failed = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-red-100">
    <div className="bg-white border border-red-400 text-red-700 p-6 rounded shadow-lg">
      <div className="flex items-center mb-4">
        {/* <RiVerifiedBadgeFill className="text-red-500 text-4xl" /> */}
        <span className="text-xl ml-2">Failed!</span>
      </div>
      <p className="text-lg mb-4">Your payment was not successful.</p>
      <div className="flex justify-center">
        <button
          // onClick={handleDashboardRedirect}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          aria-label="Try again"
        >
          please try again
        </button>
      </div>
    </div>
  </div>
  )
}

export default Failed