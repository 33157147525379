import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SubscriptionForm = () => {
  const location = useLocation();
  const transactionType = location.state?.transactionType || "subscription";

  const [company, setCompany] = useState(null);

  const [formData, setFormData] = useState({
    username: "",
    subscription_interval: "",
    subscription_amount: 0,
    transaction_type: transactionType, // Default transaction type
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch company data when username changes
    if (formData.username.trim()) {
      const fetchCompany = async () => {
        try {
          setLoading(true);
          setError(null); //
          const response = await fetch(
            `https://zola.technology/api/company/get/${formData.username}`
          );
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(
              errorData.message || `Error: ${response.statusText}`
            );
          }
          const data = await response.json();
          setCompany(data);
          toast.success("Company Data Retrieved!")
        } catch (err) {
          setError(err.message);
          setCompany(null);
          // toast.error("Company Data Not Retrieved!")
        } finally {
          setLoading(false);
        }
      };

      fetchCompany();
    } else {
      setCompany(null);
      setError(null);
    }
  }, [formData.username]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setFormData((prevData) => ({
        ...prevData,
        username: "",
        phone_number: "",
        email: "",
        transaction_type: transactionType,
      }));
    }
  }, [transactionType]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const currency = company?.phone_number.startsWith("254") ? "KES" : "USD";


  const subscriptionAmount = parseFloat(formData.subscription_amount) || 0;
  const grandTotal =
    formData.subscription_interval === "year"
      ? subscriptionAmount * 12
      : subscriptionAmount;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!formData.username || !company) {
      toast.error("The company does not exist. Please check the username.");
      return;
    }
    const paymentData = {
      username: formData.username,
      phone_number: company?.phone_number,
      email: company?.email,
      subscription_interval: formData.subscription_interval,
      subscription_amount: grandTotal,
      transaction_type: formData.transaction_type,
      currency: currency,
    };

    try {
      const paymentResponse = await fetch(
        "https://zola.technology/api/company/make-payment/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentData),
        }
      );

      const paymentDataResponse = await paymentResponse.json();

      if (!paymentResponse.ok) {
        setErrors(paymentDataResponse);
        toast.error(typeof errors === "string" ? errors : errors?.error || "An unknown error occurred");
        console.log(errors?.error)
        setLoading(false);
        return;
      }

      const receivedUrl = paymentDataResponse.url;

      toast.success("Subscription successful!");

      const emailData = {
        subject: "Complete Subscription",
        message: `Please complete your subscription using the following link: ${receivedUrl}`,
        recipients: [company?.email],
      };

      const emailResponse = await fetch(
        "https://zola.technology/api/send-bulk-emails/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailData),
        }
      );

      if (emailResponse.ok) {
        toast.success("Email sent successfully!");
      } else {
        const emailErrorData = await emailResponse.json();
        toast.error(emailErrorData.message || "Error sending email.");
      }
      
      setFormData({
        ...formData,
        subscription_interval: formData.subscription_interval,
        subscription_amount: grandTotal,
      });
      setErrors({});
    } catch (error) {
      console.error("Error in submission process:", error);
      toast.error("An error occurred during submission!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <form
        onSubmit={handleSubmit}
        className="space-y-8 max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md"
      >
        <style>
          {`
          .input {
            width: 73%;
            padding: 0.5rem 1rem;
            border: 1px solid #D1D5DB;
            border-radius: 0.375rem;
            transition: border-color 0.3s;
          }
          .input:focus {
            outline: none;
            border-color: #3B82F6;
          }
        `}
        </style>

        <div>
          <h2 className="text-xl font-semibold text-gray-700">
            Subscription Details
          </h2>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 mt-4">
            {/* Username */}
            <div>
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Username
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                placeholder="Enter your username"
                className="input"
                required
              />
            </div>

            {/* Email */}
            {/* <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                className="input"
                required
              />
            </div> */}

            {/* Phone Number */}
            {/* <div>
              <label
                htmlFor="phone_number"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Phone Number
              </label>
              <PhoneInput
                country={"us"}
                value={formData.phone_number}
                onChange={handlePhoneChange}
                inputClass="input"
                required
              />
            </div> */}

            {/* Subscription Amount */}
            <div>
              <label
                htmlFor="subscription_amount"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Subscription Amount Per Month ({currency})
              </label>
              <input
                type="number"
                id="subscription_amount"
                name="subscription_amount"
                value={formData.subscription_amount}
                onChange={handleChange}
                placeholder="Enter the subscription amount"
                className="input"
                required
              />
            </div>

            {/* Subscription Interval */}
            <div>
              <label
                htmlFor="subscription_interval"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Subscription Interval
              </label>
              <select
                id="subscription_interval"
                name="subscription_interval"
                value={formData.subscription_interval}
                onChange={handleChange}
                className="input"
                required
              >
                <option value="">Select Subscription Interval</option>
                <option value="month">Monthly</option>
                <option value="year">Yearly</option>
              </select>
            </div>

            {/* Grand Total */}
            <div className="mt-4">
              <span className="block text-lg font-semibold">
                Grand Total: {currency} {grandTotal.toFixed(2)}
              </span>
            </div>
          </div>

          <button
            aria-label="Submit"
            disabled={loading}
            aria-disabled={loading}
            type="submit"
            className="w-full py-3 px-6 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 mt-24 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </>
  );
};

export default SubscriptionForm;
