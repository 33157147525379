import DashboardHeader from "./DashboardHeader";
import Taps from "./charts/Taps";
import BarGraph from "./charts/AreaGraph";
import ReviewsChart from "./charts/ReviewsChart";
import { Link, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import AreaGraph from "./charts/AreaGraph";
import FeedbackLineChart from "./FeedbackLineChart";

const Analytics = () => {
  const [feedbackData, setFeedbackData] = useState([]);
  const { isExpired } = useOutletContext();

  useEffect(() => {
    // if (isExpired) {
    //   alert("Your session has expired. Please login again.");
    //   window.location.href = "/login";
    // }
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.href = "/login";
    }

    const getFeedBackData = async () => {
      try {
        const response = await fetch("https://zola.technology/api/company/feedback/list-categorised/", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setFeedbackData(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching feedback data:", error);
      }
    }

    getFeedBackData();

  }, []);

   // Chart options
   const chartOptions = feedbackData
   ? {
       series: [
         {
           name: "Feedback Count",
           data: [
             feedbackData.positive_feedback_count,
             feedbackData.neutral_feedback_count,
             feedbackData.negative_feedback_count,
           ],
         },
       ],
       chart: {
         type: "bar",
         height: 350,
         toolbar: { show: false },
       },
       plotOptions: {
         bar: {
           horizontal: true,
           distributed: true,
         },
       },
       colors: ["#28a745", "#ffc107", "#dc3545"], // Colors for positive, neutral, and negative
       xaxis: {
         categories: ["Positive", "Neutral", "Negative"], // Feedback types
       },
       
       tooltip: {
         y: {
           formatter: (val) => `${val} Feedbacks`,
         },
       },
     }
   : null;
  return (
    <div className="md:p-2">
      <div className={`relative ${isExpired ? "blur-sm" : ""}`}>
        <div className="flex  text-[#282A3E] items-center mb-4 ">
        <DashboardHeader title="Analytics" />
        </div>
        <div className="flex justify-center mt-2">
          <div className="rounded-[16px]  w-full">
            <AreaGraph />
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 my-8">
          <Taps className="md:col-span-1" />
          <ReviewsChart />
        </div>
        <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-4">
          <div className="rounded-lg   bg-white w-full">
            <div className="px-6 py-4  border-b ">
              <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200 flex items-center gap-2">
                Feedback Overview
                </h2>
            </div>
            <div className="p-6">
              {/* Render chart if data is available */}
            {feedbackData && (
              <ReactApexChart
                options={chartOptions}
                series={chartOptions.series}
                type="bar"
                height={400}
              />
            )}
              </div>  
          </div>
          <div className="rounded-lg  w-full bg-white ">
            <FeedbackLineChart />
          </div>
        </div>
      </div>
      {isExpired && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-80 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-8 text-center shadow-lg max-w-md">
            <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
              Subscription Expired
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              Your subscription has expired. Please renew to regain full access
              to the dashboard.
            </p>
            <Link to="/dashboard/setting/billing">
              <button aria-label="Go to Billing" className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 transition-colors">
                Go to Billing
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Analytics;
