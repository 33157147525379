import React from "react";
import PropTypes from "prop-types";
import ApexCharts from "react-apexcharts";
import { PiTrophyFill, PiArrowCircleLeft, PiArrowCircleRight } from "react-icons/pi";

const PastWinnersComponent = ({
  totalReviews,
  reviewData,
  pastWinners,
  currentWinnerIndex,
  prevWinner,
  nextWinner,
  badgeCover1,
}) => {
  return (
    <div className="w-full mt-8 grid md:grid-cols-2 xs:grid-cols-1 gap-6">
      {/* Past Winners Section */}
      <div className="p-6 dark:shadow-md w-full max-w-4xl mx-auto bg-white rounded-[16px] border border-[#EAEAEC] dark:bg-gray-800">
        <div className="flex justify-between items-center mb-6">
          <h3 className="font-medium text-xl sm:text-2xl text-[#292D32] dark:text-white tracking-tight flex items-center">
            <PiTrophyFill size={24} className="mr-3 text-[#F5AD1B]" /> Past Winners
          </h3>
          <div className="border border-gray-300 rounded-md px-2 py-[4px]">
            <select className="text-gray-700 bg-white focus:outline-none">
              <option value="April">Month</option>
            </select>
          </div>
        </div>

        <div className="relative flex items-center justify-center">
          {/* Previous Button */}
          <button
            aria-label="Previous Winner"
            onClick={prevWinner}
            className="absolute left-0 bg-white dark:bg-gray-600 p-2 h-10 w-10 flex items-center justify-center rounded-full shadow-sm hover:bg-gray-300 dark:hover:bg-gray-500 transition-all duration-300 ease-in-out text-[24px]"
          >
            <PiArrowCircleLeft className="text-[#454861]" />
          </button>

          {/* Cards Container */}
          <div className="flex space-x-4 justify-center w-full items-center">
            {/* Previous Winner Card */}
            <div
              className={`flex flex-col items-center p-2 rounded-[10px] transition-transform duration-500 ease-in-out border-[0.6px] border-[#F5AD1B] h-[220px] w-[118px] ${
                currentWinnerIndex === 0 ? "hidden" : "block"
              } ${currentWinnerIndex === 0 ? "" : "transform scale-90 opacity-80"}`}
            >
              <img
                src="https://t3.ftcdn.net/jpg/06/33/54/78/360_F_633547842_AugYzexTpMJ9z1YcpTKUBoqBF0CUCk10.jpg"
                alt={pastWinners[currentWinnerIndex - 1]?.name}
                className="w-20 h-20 rounded-full"
              />
              <p className="text-[9px] font-semibold text-[#454861] dark:text-white">
                {pastWinners[currentWinnerIndex - 1]?.name}
              </p>
              <div
                className="md:w-[85px] xs:w-[75px] p-2 text-center rounded-t-lg text-[#FEFEFF] mt-[21px]"
                style={{
                  backgroundImage: `url(${badgeCover1})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <p className="text-[16px] font-semibold">
                  1st
                  <br /> Place
                </p>
                <p className="text-[9px] font-semibold mt-1">
                  {pastWinners[currentWinnerIndex - 1]?.reviews} <br /> Reward Total
                </p>
              </div>
            </div>

            {/* Current Winner Card */}
            <div className="flex flex-col items-center justify-center p-6 rounded-[12px] transition-transform duration-500 transform hover:scale-105 border-[0.8px] border-[#F5AD1B] w-4/12 md:h-[288px] xs:h-[250px]">
              <img
                src="https://t3.ftcdn.net/jpg/06/33/54/78/360_F_633547842_AugYzexTpMJ9z1YcpTKUBoqBF0CUCk10.jpg"
                alt={pastWinners[currentWinnerIndex]?.name}
                className="w-24 h-24 rounded-full mb-4"
              />
              <p className="text-[12px] font-semibold text-[#454861] dark:text-white">
                {pastWinners[currentWinnerIndex]?.name}
              </p>
              <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                {pastWinners[currentWinnerIndex]?.month} {pastWinners[currentWinnerIndex]?.year}
              </p>
              <div
                className="md:w-[112px] xs:w-[80px] p-2 text-center rounded-t-lg text-white"
                style={{
                  backgroundImage: `url(${badgeCover1})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <p className="text-[20px] font-semibold">
                  1st <br /> Place
                </p>
                <p className="text-[12px] font-semibold mt-1">
                  {pastWinners[currentWinnerIndex]?.reviews} <br /> Reward Total
                </p>
              </div>
            </div>

            {/* Next Winner Card */}
            <div
              className={`flex flex-col items-center p-4 rounded-2xl transition-transform duration-500 ease-in-out border-[0.6px] border-[#F5AD1B] w-4/12 h-[220px] ${
                currentWinnerIndex === pastWinners.length - 1 ? "hidden" : "block"
              }`}
            >
              <img
                src="https://t3.ftcdn.net/jpg/06/33/54/78/360_F_633547842_AugYzexTpMJ9z1YcpTKUBoqBF0CUCk10.jpg"
                alt={pastWinners[currentWinnerIndex + 1]?.name}
                className="w-20 h-20 rounded-full"
              />
              <p className="text-[9px] font-semibold text-gray-900 dark:text-white">
                {pastWinners[currentWinnerIndex + 1]?.name}
              </p>
              <div
                className="md:w-[85px] xs:w-[75px] p-2 text-center rounded-t-lg text-[#FEFEFF] mt-[13px]"
                style={{
                  backgroundImage: `url(${badgeCover1})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <p className="text-[16px] font-semibold">
                  1st <br /> Place
                </p>
                <p className="text-[9px] font-semibold mt-1">
                  {pastWinners[currentWinnerIndex + 1]?.reviews} Reward Total
                </p>
              </div>
            </div>
          </div>

          {/* Next Button */}
          <button
            aria-label="Next Winner"
            onClick={nextWinner}
            className="absolute right-0 bg-white dark:bg-gray-600 p-2 h-10 w-10 flex items-center justify-center rounded-full shadow-sm hover:bg-gray-300 dark:hover:bg-gray-500 transition-all duration-300 ease-in-out text-[24px]"
          >
            <PiArrowCircleRight className="text-[#454861]" />
          </button>
        </div>
      </div>

      {/* Feedback Chart Section */}
      <div className="p-6 md:p-4 rounded-[13px] dark:bg-gray-800 bg-white dark:text-white w-full border border-[#EAEAEC] h-auto">
        <div className="flex items-center justify-between">
          <div>
            <h3 className="font-medium text-[20px] text-[#6F707E] dark:text-white">Total Feedbacks</h3>
            <p className="text-[40px] font-semibold text-[#454861]">{totalReviews}</p>
          </div>
        </div>
        <div>
          <ApexCharts options={reviewData.options} series={reviewData.series} type="area" height={300} />
        </div>
      </div>
    </div>
  );
};

PastWinnersComponent.propTypes = {
  totalReviews: PropTypes.number.isRequired,
  reviewData: PropTypes.object.isRequired,
  pastWinners: PropTypes.array.isRequired,
  currentWinnerIndex: PropTypes.number.isRequired,
  prevWinner: PropTypes.func.isRequired,
  nextWinner: PropTypes.func.isRequired,
  badgeCover1: PropTypes.string.isRequired,
};

export default PastWinnersComponent;
