import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubscriptionComponent from "./SubscriptionComponent";
import { MdVerified } from "react-icons/md";

// Billing Component
const Billing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state

  // Retrieve userData from local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  const phoneNumber = userData?.phone_number || "";
  const username = userData?.username || "";
  const isKenyanAccount = phoneNumber.startsWith("254");

  // Prices for subscription options
  const monthlyPrice = isKenyanAccount ? 3000 : 49;
  const yearlyPrice = isKenyanAccount ? 30000 : 480;

  const handleManageSubscriptionClick = () => {
    setIsModalOpen(true);
  };

  const handleUpdateSubscriptionClick = () => {
    setIsUpdateModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsUpdateModalOpen(false);
  };

  // Function to handle subscription updates
  const handleUpdateSubscription = async (interval, amount) => {
    const token = localStorage.getItem("token"); // Retrieve the token from local storage
    const updateUrl =
      "https://zola.technology/api/company/stripe-update-subscription/";
    const paymentUrl = "https://zola.technology/api/company/make-payment/";
    const subscriptionType = interval;
    const subscriptionAmount = amount;

    setIsLoading(true); // Set loading state to true
    try {
      const updateResponse = await fetch(updateUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Token is required for updating subscription
        },
        body: JSON.stringify({
          subscription_interval: subscriptionType, // Either 'month' or 'year'
          subscription_amount: subscriptionAmount, // Price based on selected plan
        }),
      });

      const updateResult = await updateResponse.json(); // Parse the response JSON

      if (updateResponse.ok) {
        toast.success(
          `Your subscription has been updated to ${interval}ly plan for ${amount}${
            isKenyanAccount ? " KSH" : " USD"
          }.`
        );
      } else {
        throw new Error(
          updateResult.error || "Failed to update the subscription."
        );
      }
    } catch (error) {
      toast.error(
        "Error updating the subscription. Redirecting to complete payment."
      );

      // If update fails, make payment request to get Stripe URL
      try {
        const paymentResponse = await fetch(paymentUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username, // Ensure username is being passed
            phone_number: phoneNumber, // Ensure phone_number is being passed
            subscription_interval: subscriptionType,
            subscription_amount: subscriptionAmount,
            transaction_type: "subscription",
          }),
        });

        const paymentResult = await paymentResponse.json();

        if (paymentResponse.ok && paymentResult?.url) {
          window.location.href = paymentResult.url; // Redirect to Stripe payment URL
        } else {
          toast.error("Failed to initiate payment. Please try again.");
        }
      } catch (paymentError) {
        console.error("Payment error:", paymentError); // Log payment error
        toast.error(
          "There was an error processing your payment. Please check your network connection."
        );
      }
    } finally {
      setIsLoading(false); // Reset loading state after completion
      setIsUpdateModalOpen(false); // Close the modal after completion
    }
  };

  // Function to handle cancel subscription
  const handleCancelSubscription = async () => {
    const token = localStorage.getItem("token"); // Retrieve the token from local storage
    const url =
      "https://zola.technology/api/company/cancel-stripe-subscription/";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });

      if (response.ok) {
        toast.success("Your subscription has been successfully canceled.");
      } else {
        toast.error("Failed to cancel the subscription. Please try again.");
      }
    } catch (error) {
      toast.error(
        "There was an error canceling your subscription. Please check your network connection."
      );
    } finally {
      setIsModalOpen(false); // Close the modal after canceling
    }
  };

  // Function to handle resume subscription
  const handleResumeSubscription = async () => {
    const token = localStorage.getItem("token"); // Retrieve the token from local storage
    const resumeUrl =
      "https://zola.technology/api/company/resume-stripe-subscription/";

    try {
      const response = await fetch(resumeUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });

      if (response.ok) {
        toast.success("Your subscription has been resumed.");
      } else {
        toast.error("Failed to resume the subscription. Please try again.");
      }
    } catch (error) {
      toast.error(
        "There was an error resuming your subscription. Please check your network connection."
      );
    } finally {
      setIsModalOpen(false); // Close the modal after resuming
    }
  };

  return (
    <div>
      {/* Toast Container */}
      <ToastContainer />

      <div className="flex flex-col md:flex-row md:items-center gap-4 md:gap-8">
        {/* Title */}
        <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
          Manage Payment & Promos
        </h3>

        {/* Status Section */}
        <div className="flex items-center">
          <span className="text-gray-700 dark:text-gray-300 mr-2 text-xl">Status:</span>
          <span className="text-green-600 font-semibold text-xl mr-1">Active</span>
          <MdVerified className="bg-green-500 text-white p-1 text-2xl rounded-full" />
        </div>

      </div>

      {/* Subscription Section */}
      <div className=" bg-white dark:bg-gray-800 rounded-lg mt-4 md:mt-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-gray-700 dark:text-gray-300 mb-1">
              Subscription Amount (KES):
            </label>
            <input
              type="text"
              value="KSh 3000.00"
              className="w-full p-2 border rounded-md text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
              readOnly
            />
          </div>
          <div>
            <label className="block text-gray-700 dark:text-gray-300 mb-1">
              Subscription Interval:
            </label>
            <select
              className="w-full p-2 border rounded-md text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
              defaultValue="Monthly"
            >
              <option>Monthly</option>
              <option>Yearly</option>
            </select>
          </div>
        </div>

        <div className="flex w-full space-x-4">
          <button
            aria-label="Manage Subscription"
            className="bg-blue-600 w-1/2 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded-md transition duration-200"
            onClick={handleManageSubscriptionClick}
          >
            Manage
          </button>
          <button
            aria-label="Update Subscription"
            className="border w-1/2 border-blue-600 text-blue-600 hover:bg-blue-50 dark:hover:bg-gray-700 font-semibold py-2 px-6 rounded-md transition duration-200"
            onClick={handleUpdateSubscriptionClick}
          >
            Update
          </button>
        </div>
      </div>

      {/* Cancel Subscription Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-md shadow-md">
            <h4 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-4">
              Manage Subscription
            </h4>
            <p className="text-gray-900 dark:text-gray-100 mb-6">
              Are you sure you want to cancel your subscription?
            </p>
            <div className="flex flex-col md:flex-row justify-end gap-4">
            <button
                aria-label="Resume Subscription"
                className="bg-gray-300 dark:bg-gray-700 text-black dark:text-gray-300 px-4 py-2 rounded-md"
                onClick={handleResumeSubscription} // Resume subscription action
              >
                Resume Subscription
              </button>
              <button
                aria-label="Cancel Subscription"
                className="bg-red-600 dark:bg-red-700 text-white px-4 py-2 rounded-md"
                onClick={handleCancelSubscription}
              >
                Yes, Cancel Subscription
              </button>
            </div>
            {/* Close Modal Button */}
            <div className="flex justify-end mt-4">
              <button
                aria-label="Close Modal"
                className="bg-gray-300 dark:bg-gray-700 text-black dark:text-gray-300 px-4 py-2 rounded-md"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Update Subscription Modal */}
      {isUpdateModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center w-full p-4">
          <div className="bg-white w-full md:w-auto dark:bg-gray-800 p-6 rounded-md shadow-md">
            <h4 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-4">
              Choose Subscription Plan
            </h4>
            <div className="flex flex-col md:flex-row gap-4">
              <div className="bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-lg text-center">
                <h5 className="text-lg font-bold text-gray-900 dark:text-gray-100">
                  Monthly Subscription
                </h5>
                <p className="text-gray-600 dark:text-gray-400 mt-2">
                  {isKenyanAccount ? "3000 KSH" : "$49"}
                </p>
                <button
                  aria-label="Choose Monthly Subscription"
                  className="bg-blue-600 dark:bg-blue-500 text-white px-4 py-2 mt-4 rounded-md"
                  onClick={() =>
                    handleUpdateSubscription("month", monthlyPrice)
                  }
                  disabled={isLoading} // Disable button while loading
                >
                  {isLoading ? "Updating..." : "Choose Monthly"}
                </button>
              </div>

              <div className="bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-lg text-center">
                <h5 className="text-lg font-bold text-gray-900 dark:text-gray-100">
                  Yearly Subscription
                </h5>
                <p className="text-gray-600 dark:text-gray-400 mt-2">
                  {isKenyanAccount ? "30000 KSH" : "$480"}
                </p>
                <button
                  aria-label="Choose Yearly Subscription"
                  className="bg-blue-600 dark:bg-blue-500 text-white px-4 py-2 mt-4 rounded-md"
                  onClick={() => handleUpdateSubscription("year", yearlyPrice)}
                  disabled={isLoading} // Disable button while loading
                >
                  {isLoading ? "Updating..." : "Choose Yearly"}
                </button>
              </div>
            </div>
            <div className="flex justify-end mt-4">
              <button
                aria-label="Close Modal"
                className="bg-gray-300 dark:bg-gray-700 text-black dark:text-gray-300 px-4 py-2 rounded-md"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {/* <SubscriptionComponent /> */}
    </div>
  );
};

export default Billing;
