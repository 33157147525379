import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Alert = ({ message, type }) => {
  const alertStyles = {
    success: "bg-green-100 text-green-700 border-green-500",
    error: "bg-red-100 text-red-700 border-red-500"
  };

  return (
    <div className={`border-l-4 p-4 mb-4 ${alertStyles[type]} rounded-md`}>
      {message}
    </div>
  );
};

const AddEmployee = () => {
  const [fullName, setFullName] = useState('');
  const [userName, setUserName] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate username length
    if (userName.length < 6) {
      setError('Username must be at least 6 characters long');
      return;
    }
    
    const data = {
      name: fullName,
      username: userName
    };

    try {
      const response = await fetch('https://zola.technology/api/company/employees/add/', {
        method: 'POST',
        headers:{
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.username[0] || 'Error adding employee');
        setSuccess(null);
        throw new Error('Network response was not ok');
      }

      // Handle success
      setSuccess('Employee added successfully');
      setError(null);
      
      // Optionally clear form inputs after successful submission
      setFullName('');
      setUserName('');

      // Redirect to teammanagement page
      setTimeout(() => navigate('/dashboard/members'), 500); // Wait for 2 seconds before redirecting
    } catch (error) {
      console.error('Error adding employee:', error);
      // Handle error case
    }
  };

  return (
    <div className="max-w-md mx-auto mt-28 p-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg">
      <h2 className="text-xl font-bold text-black dark:text-white mb-4">Add Employee</h2>
      {success && <Alert message={success} type="success" />}
      {error && <Alert message={error} type="error" />}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="fullName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Full Name:
          </label>
          <input
            id="fullName"
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-100 dark:bg-gray-900 text-black dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600 focus:border-blue-500 dark:focus:border-blue-600 sm:text-sm"
            placeholder="Enter full name"
            required
          />
        </div>
        <div>
          <label htmlFor="userName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Username:
          </label>
          <input
            id="userName"
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-100 dark:bg-gray-900 text-black dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600 focus:border-blue-500 dark:focus:border-blue-600 sm:text-sm"
            placeholder="Enter username"
            required
          />
        </div>
        <div className="text-center">
          <button
            aria-label="Submit the form"
            role="button"
            tabIndex="0"
            type="submit"
            className="inline-block px-4 py-2 bg-blue-600 dark:bg-blue-700 text-white rounded-md shadow-sm hover:bg-blue-700 dark:hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600 focus:ring-offset-2"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddEmployee;
