import React, { useState, useEffect, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchPlacePredictions } from '../../pages/SignUp/googlePlaces';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'; // Import icons

function CreateBranchModal({ isOpen, onClose, onSubmit }) {
  const [formData, setFormData] = useState({
    username: '',
    company_name: '',
    first_name: '',
    last_name: '',
    email: '',
    preferred_business_name: '',
    phone_number: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [placeSuggestions, setPlaceSuggestions] = useState([]);
  const [passwordType, setPasswordType] = useState('password'); // State for password visibility
  const suggestionsRef = useRef();

  useEffect(() => {
    const { company_name } = formData;
    if (company_name.length > 2) {
      const fetchSuggestions = async () => {
        const suggestions = await fetchPlacePredictions(company_name);
        setPlaceSuggestions(suggestions);
      };
      fetchSuggestions();
    } else {
      setPlaceSuggestions([]);
    }
  }, [formData.company_name]);

  const handlePlaceSelect = (description) => {
    setFormData((prev) => ({
      ...prev,
      company_name: description,
    }));
    setPlaceSuggestions([]);
  };

  const handleClickOutside = (event) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target)
    ) {
      setPlaceSuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const togglePasswordVisibility = () => {
    setPasswordType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://zola.technology/api/hq-company/create-branch-company/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        onSubmit(data);
        toast.success('Branch created successfully! Please log in to the new account to complete signup.');
        setTimeout(() => {
          onClose();
        }, 3000);
      } else {
        const errorData = await response.json();
        setErrors(errorData);
        toast.error(errorData.error || 'Something went wrong!');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 dark:bg-opacity-70">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-2xl">
        <h2 className="text-2xl text-gray-900 dark:text-white mb-4">Create New Branch</h2>

        <form onSubmit={handleFormSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Object.keys(formData).map((key) => (
              <div key={key} className="relative mb-4">
                <label className="block text-gray-700 dark:text-gray-300 capitalize">
                  {key.replace(/_/g, ' ')}
                </label>
                <div className={`relative ${key === 'password' ? 'flex items-center' : ''}`}>
                  <input
                    type={key === 'email' ? 'email' : key === 'password' ? passwordType : 'text'}
                    name={key}
                    value={formData[key] || ''}
                    onChange={handleInputChange}
                    className="p-2 border border-gray-300 dark:border-white rounded-lg bg-transparent text-gray-900 dark:text-white w-full"
                  />
                  {key === 'password' && (
                    <button
                      aria-label="Toggle password visibility"
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="absolute right-3 text-gray-600 dark:text-gray-300"
                    >
                      {passwordType === 'password' ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </button>
                  )}
                </div>
                {errors[key] && <p className="text-red-500 mt-1">{errors[key][0]}</p>}

                {key === 'company_name' && placeSuggestions.length > 0 && (
                  <div
                    ref={suggestionsRef}
                    className="absolute z-10 bg-white text-black dark:bg-gray-700 dark:text-white mt-1 rounded-md shadow-md w-full max-h-40 overflow-y-auto"
                  >
                    {placeSuggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        className="p-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                        onClick={() => handlePlaceSelect(suggestion.description)}
                      >
                        {suggestion.description}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          {errors.non_field_errors && (
            <div className="text-red-500 mb-4">{errors.non_field_errors[0]}</div>
          )}

          <div className="flex justify-end">
            <button
              type="button"
              aria-label="Cancel"
              onClick={onClose}
              className="mr-4 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              aria-label="Create Branch"
              type="submit"
              className="px-4 py-2 bg-teal-500 text-white rounded-lg hover:bg-teal-600 transition"
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Create Branch'}
            </button>
          </div>
        </form>

        <ToastContainer />
      </div>
    </div>
  );
}

export default CreateBranchModal;
