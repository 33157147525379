import { useEffect, useState } from "react";
import DashboardHeader from "./DashboardHeader";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import SkeletonCard from "./SkeletonCard";
import { TotalRevenue, PerformanceDashboard } from "./TeamPerformance/index";
import { Link, useOutletContext } from "react-router-dom";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
import OverallPerformance from "./TeamPerformance/OverallPerformance";
import ReactApexChart from "react-apexcharts";
import Reward from "./Reward";

function TeamPerformance() {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const { isExpired } = useOutletContext();
  const [isMobileScreen, setIsMobileScreen] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const token = localStorage.getItem("token");
        const month = "month";
        const response = await fetch(
          `https://zola.technology/api/company/employees/performance/filter/get-filtered-taps/?filter=${month}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setEmployees(data.employees);
      } catch (error) {
        console.error("Error fetching the employees data:", error);
      } finally {
        setLoading(false);
      }
    }

    const handleResize = () => {
      setIsMobileScreen(window.matchMedia("(max-width: 768px)").matches);
    };

    window.addEventListener("resize", handleResize);
    fetchData();
    handleResize(); // Call once to set initial state

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const calculateWeeklyTaps = (periods) => {
    const weekTaps = Array(4).fill(0); // Assuming 4 weeks in a month

    periods.forEach((period) => {
      period.dates.forEach((dateObj) => {
        const weekNumber = Math.ceil(new Date(dateObj.date).getDate() / 7); // Calculate week number
        weekTaps[weekNumber - 1] += dateObj.taps;
      });
    });

    return weekTaps;
  };

  const calculateMonthlyTaps = (periods) => {
    // Sum all taps from the periods
    return periods.reduce((total, period) => {
      return (
        total + period.dates.reduce((sum, dateObj) => sum + dateObj.taps, 0)
      );
    }, 0);
  };

  const generateData = (periods) => {
    const weekTaps = calculateWeeklyTaps(periods);
    const labels = ["Week 1", "Week 2", "Week 3", "Week 4"];

    return {
      labels,
      series: [
        {
          name: "Taps per Week",
          data: weekTaps,
        },
      ],
    };
  };

  const chartOptions = {
    chart: {
      type: "area",
      height: isMobileScreen ? 150 : 200, // Adjust height for small screens
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Hide the toolbar
      },
    },
    colors: ["#00BA34"], // Set the line color

    dataLabels: {
      enabled: false,
    },

    xaxis: {
      type: "category",
      categories: ["Week 1", "Week 2", "Week 3", "Week 4"],
      labels: {
        rotate: 0, // Set rotation to 0 to display labels horizontally
      },
      title: {
        text: "Taps per Week", // Add a data label on the x-axis
        style: {
          fontSize: "16px",
          fontWeight: "medium",
          fontStyle: "italic", // Set the text to italic
        },
      },
    },
    grid: { show: true,
      borderColor: '#F1F2F3',
      strokeDashArray: 1,
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true  } },
     },
      

    stroke: {
      curve: "straight", // Set the curve to straight
      width: 2, // Set the line thickness
    },

    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 1,
        opacityTo: 0,
        stops: [0, 100],
        colorStops: [
          {
            offset: 0,
            color: "#D8FECA",
            opacity: 1,
          },
          {
            offset: 100,
            color: "#ACFFC300",
            opacity: 0,
          },
        ],
      },
    },

    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const currentItems = employees.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(employees.length / itemsPerPage);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonth = monthNames[new Date().getMonth()];
  const userData = JSON.parse(localStorage.getItem("userData"));
  const companyType = userData.company_type;
  return (
    <div className="p-2  text-black dark:bg-gray-800 dark:text-white min-h-screen xs:w-full lg:w-full">
      {loading ? (
          <div className="flex justify-center items-center min-h-screen mt-[-150px] ">
            <div className="loader "></div>
          </div>
        ) : (
          <>
      <div className={`relative  ${isExpired ? "blur-sm" : ""}`}>
        <div className="flex text-[#282A3E] items-center mb-4">
        <DashboardHeader title="Team Performance" />

        </div>
        
        
            <div className="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
              {currentItems.map((employee) => {
                const monthlyTaps = calculateMonthlyTaps(employee.periods); // Get taps for the current month
                const data = generateData(employee.periods);

                return (
                  <div
                    key={employee.id}
                    className="p-4 h-80  dark:border-gray-600 bg-white dark:bg-gray-700 rounded-[12px]  lg:w-full xs:w-full"
                  >
                    <div className="">
                      <div className="flex justify-between items-center">
                        <div className="w-3/6 flex items-center">
                          <p className="w-[40px] h-[40px] rounded-full flex items-center justify-center text-white font-bold text-[12px] p-4 bg-blue-600">
                            {`${employee.name.split(" ")[0][0].toUpperCase()}${
                              employee.name.split(" ")[1]
                                ? employee.name.split(" ")[1][0].toUpperCase()
                                : ""
                            }`}
                          </p>
                          <h3 className="text-[14px] font-semibold ml-2 text-[#6F707E]">
                            {employee.name.split(" ")[0]} <br />
                            {employee.name.split(" ")[1]}
                          </h3>
                        </div>
                      </div>
                      <div className="flex space-x-2 items-center">
                        <p className="text-[24px] font-semibold text-[#454861]">
                          {monthlyTaps}{" "}
                          <span className="text-[#454861] text-[16px]">
                            Taps
                          </span>{" "}
                          {/* Display monthly taps */}
                        </p>
                      </div>
                    </div>
                    <div className="lg:w-full xs:w-full mb-4">
                      <ReactApexChart
                        options={chartOptions}
                        series={data.series}
                        type="area"
                        height={chartOptions.chart.height}
                        width={isMobileScreen ? "80%" : "100%"}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-center m-2">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  aria-label={`Go to page ${index + 1}`}
                  key={index}
                  className={`px-4 py-2 mx-1 rounded ${
                    index + 1 === currentPage
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300 dark:bg-gray-700 text-black dark:text-white"
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
         

        <div className="flex flex-col lg:flex-row lg:justify-between space-x-4 ">
          <div className="w-full lg:w-1/2 lg:mr-1 mb-4 lg:mb-0 ">
            <OverallPerformance />
          </div>
          <div className="w-full lg:w-1/2 lg:ml-1 ">
            <PerformanceDashboard />
          </div>
        </div>

        {companyType === "team" && <TotalRevenue />}
        {companyType === "team" && <Reward />}
      </div>
       </>
      )}
      {isExpired && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-80 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-8 text-center shadow-lg max-w-md">
            <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
              Subscription Expired
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              Your subscription has expired. Please renew to regain full access
              to the dashboard.
            </p>
            <Link to="/dashboard/setting/billing">
              <button aria-label="Go to Billing" className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 transition-colors">
                Go to Billing
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default TeamPerformance;
