import { data } from 'autoprefixer';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const fetchPerformanceData = async (filter) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`https://zola.technology/api/company/analytics/performance/filtered/get/?filter=${filter}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
};
const OverallPerformance = () => {
  const [filter, setFilter] = useState('week'); // Default filter is 'week'
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'area',
      height: 300,
      toolbar: { show: false },
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    dataLabels: { enabled: false },
    tooltip: { y: { formatter: val => `${val}%` } },
    xaxis: {
      categories: [],
      labels: { style: { colors: '#6F707E', fontSize: '12px', fontWeight: 'medium', },
      rotate: 0,
      offsetX: 4,
     },
    },
    yaxis: {
      labels: {
        formatter: val => `${val}%`,
        style: { colors: '#6F707E', fontSize: '12px' },
      },
    },
    grid: { show: true,
      borderColor: '#F1F2F3',
      strokeDashArray: 1,
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true  } },
     },
    colors: ['#22c55e'],
    markers: {
      size: 0,
      colors: ['#22c55e'],
      strokeWidth: 2,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.2,
        gradientToColors: ['#22c55e'],
        inverseColors: false,
        opacityFrom: 0.3,
        opacityTo: 0,
        stops: [0, 200],
      },
    },
  });
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchPerformanceData(filter);
      const periods = data.period_performance.map(item => item.period);
      const performanceScores = data.period_performance.map(item => item.performance_score);

      // Remove the year from the periods and format the dates
      const categoriesWithoutYear = periods.map(period => {
        const parts = period.split(" ");
        if (filter === "week") {
          return `${parts[1]} ${parts[2]}`;
        }
        return parts.slice(0, parts.length - 1).join(" ");
      });

      setChartOptions(prev => ({
        ...prev,
        xaxis: { ...prev.xaxis, categories: categoriesWithoutYear }
      }));

      setSeries([{ name: 'Performance Score', data: performanceScores }]);
    };

    fetchData();
  }, [filter]);

  const handleResize = () => {
    setIsMobileScreen(window.matchMedia("(max-width: 768px)").matches);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial state

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);




  return (
    <div className=" bg-white rounded-[12px] max-w-full xs:w-full h-[400px]">
      <div className="md:px-4 md:py-2 sm:p-6 flex justify-between items-center  border-b border-[#EAEAEC]">
        <div>
        <h2 className="text-[20px] font-semibold text-[#454861]">Performance</h2>
        <span className="text-[12px] font-medium text-[#9C9DA6] mb-4">Overall performance overview based on this {filter}'s data.</span>
      
        </div>
         <div>
          <select
            className="border text-[#454861] bg-white rounded-[5px] border-[#EAEAEC] px-2 font-semibold  text-[14px] h-[30px] focus:outline-none"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="week">Week</option>
            <option value="month">Month</option>
            <option value="year">Year</option>
          </select>
        </div>
        
      </div>
      <div className=''>
     
      <div className="md:w-full xs:w-full md:px-4 sm:px-6">
      <ReactApexChart options={chartOptions} series={series} type="area" height={isMobileScreen ? 200 : 300} width={isMobileScreen ? "100%" : "100%"} />
      </div>

      </div>
     
    </div>
  );
};

export default OverallPerformance;
