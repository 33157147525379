import React from "react";
import cover from "../assets/cover.png";
import cover2 from "../assets/cover2.png";
import logo from "../assets/logo.png";
import { FaLinkedinIn } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import twitter from "../assets/twitter.png";
import { FaFacebook } from "react-icons/fa6";
import { PiThumbsUpFill } from "react-icons/pi";
import { PiPencilSimpleLineFill } from "react-icons/pi";
import { PiShareNetworkFill } from "react-icons/pi";

const MobileSurvey = () => {
  return (
    <div className="w-full min-h-screen flex flex-col  overflow-hidden lg:overflow-hidden ">
      <div
        className="w-full h-[200px] top-0"
        style={{
          backgroundImage: `url(${cover})`, // Use the imported image
          backgroundSize: "cover", // Cover the entire div
          backgroundPosition: "center", // Center the image
          backgroundRepeat: "no-repeat", // Do not repeat the image
        }}
      ></div>
      <div className="xs:mt-[-70px] lg:mt-[-120px] ml-4">
        <img
          src={cover2}
          alt="logo"
          className="xs:w-10/12 xs:h-7/12 md:w-[550px] md:h-[400px]"
        />
      </div>
      <div className="ml-12 xs:mt-[-60px] md:mt-[-130px] w-11/12">
        <p className="text-[#454861] xs:text-[20px] lg:text-[36px] font-semibold">
          Hello Customer
        </p>
        <p className="text-[#6F707E] xs:text-[16px] lg:text-[20px] font-normal">
          Hello nice to have you with us please check out our social media or
          take any of the actions below
        </p>
        <div className="flex space-x-10 p-4 items-center">
          <div className="w-[32px] h-[32px] flex justify-center items-center rounded-full bg-[#0C4EF8]">
            <FaLinkedinIn className="text-white w-[20px] h-[20px]" />
          </div>

          <div>
            <AiFillInstagram className="text-[#0C4EF8] w-9 h-9" />
          </div>
          <div>
            <img src={twitter} alt="twitter" className="w-6 h-6" />
          </div>
          <div>
            <FaFacebook className="text-[#0C4EF8] w-8 h-8" />
          </div>
        </div>
      </div>
      <div className="p-4 md:flex md:flex-col md:justify-center md:items-center space-y-4">
        <button
          aria-label="Give us a review"
          className="bg-[#0C4EF8] rounded-[8px] h-[54px] flex space-x-4 xs:w-full md:w-6/12 items-center justify-center"
          style={{
            boxShadow:
              "0px 6.37px 9.56px -3.19px #10182808, 0px 19.11px 25.48px -6.37px #10182814",
          }}
        >
          <span className="text-[#FEFEFF] text-[16px] font-normal">
            Give us a review
          </span>
          <PiThumbsUpFill className="w-8 h-8 text-white" />
        </button>
        <button
          aria-label="Take a survey"
          className="bg-[#0C4EF8] rounded-[8px] h-[54px] flex space-x-4 xs:w-full md:w-6/12 items-center justify-center"
          style={{
            boxShadow:
              "0px 6.37px 9.56px -3.19px #10182808, 0px 19.11px 25.48px -6.37px #10182814",
          }}
        >
          <span className="text-[#FEFEFF] text-[16px] font-normal">
            Take a survey
          </span>
          <PiPencilSimpleLineFill className="w-8 h-8 text-white" />
        </button>
        <button
          aria-label="Share"
          className="bg-[#0C4EF8] rounded-[8px] h-[54px] flex space-x-4 xs:w-full md:w-6/12 items-center justify-center"
          style={{
            boxShadow:
              "0px 6.37px 9.56px -3.19px #10182808, 0px 19.11px 25.48px -6.37px #10182814",
          }}
        >
          <span className="text-[#FEFEFF] text-[16px] font-normal">Share</span>
          <PiShareNetworkFill className="w-8 h-8 text-white" />
        </button>
      </div>
      <div className="flex items-center xs:w-full md:w-11/12 justify-center p-4">
        <div className="flex flex-col items-center justify-center ">
          <p className="flex item-center text-[#454861] text-[20px] p-4">
            Powered by
          </p>
          <img src={logo} alt="logo" className="w-18 h-10" />
        </div>
      </div>
    </div>
  );
};

export default MobileSurvey;
