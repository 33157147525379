import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { MdOutlineSentimentSatisfied } from "react-icons/md";

const getSentimentEmoji = (averageRating) => {
  if (averageRating >= 4.5) return "😊";
  if (averageRating >= 3) return "🙂";
  if (averageRating >= 2) return "😐";
  return "☹️";
};

const AverageSentiment = ({ className }) => {
  const [currentWeekEmoji, setCurrentWeekEmoji] = useState("");
  const [previousWeekEmoji, setPreviousWeekEmoji] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchAverageFeedback = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }

        // Fetch data for both weeks
        const [currentWeekResponse, previousWeekResponse] = await Promise.all([
          axios.get(
            "https://zola.technology/api/company/feedback/get-average/",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                filter: "current_week",
              },
            }
          ),
          axios.get(
            "https://zola.technology/api/company/feedback/get-average/",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                filter: "previous_week",
              },
            }
          ),
        ]);

        // Extract and calculate average ratings
        const currentWeekRating = currentWeekResponse.data.averageRating || 0;
        const previousWeekRating = previousWeekResponse.data.averageRating || 0;

        // Set the sentiment emojis based on ratings
        setCurrentWeekEmoji(getSentimentEmoji(currentWeekRating));
        setPreviousWeekEmoji(getSentimentEmoji(previousWeekRating));
      } catch (error) {
        setError(error.message);
      }
    };

    fetchAverageFeedback();
  }, []);

  return (
    <div className="group bg-white xs:h-[150px]  rounded-lg lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
      <div className="flex items-center gap-3">
        <div className="bg-white text-[#e23737] p-2 rounded-lg border">
          <MdOutlineSentimentSatisfied />
        </div>
        <p className="text-[#6F707E] font-semibold text-[16px] group-hover:text-white">
          Average <br /> Sentiment
        </p>
      </div>
      <div className="flex flex-col items-center mt-4 space-y-2">
        {error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <>
            {/* Current Week Emoji */}
            <p className="text-3xl font-bold text-yellow-400 group-hover:text-white">
              {currentWeekEmoji}
            </p>

            {/* Conditionally Render Previous Week Emoji with a Note */}
            {previousWeekEmoji && (
              <div className="flex items-center space-x-2 text-gray-600">
                <span className="text-xl">{previousWeekEmoji}</span>
                <span className="text-sm font-bold text-[#e23737] group-hover:text-white">
                  Last Week
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

AverageSentiment.propTypes = {
  className: PropTypes.string,
};

export default AverageSentiment;
