import axios from "axios";
import { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import emoji from "./emoji.png";
import emojiSvg from "./emoji.svg";
import stars from "./stars.png";
import starsSvg from "./stars.svg";

const shortenCompanyName = (fullCompanyName) => {
  let mainName = fullCompanyName.split(" - ")[0];
  if (mainName === fullCompanyName) {
    mainName = fullCompanyName.split(",")[0];
  }
  return mainName;
};

function EmojiStarMobile() {
  const [selection, setSelection] = useState("emojis"); // Track selected option ("emoji" or "star")
  const [selectedStars, setSelectedStars] = useState(0); // Track selected stars
  const [preferredBusinessName, setPreferredBusinessName] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [companyData, setCompanyData] = useState([]);
  const [imageLoading, setImageLoading] = useState(true);
  const [selectedRating, setSelectedRating] = useState(0); // default to 0 or another valid rating

  const emojis = ["😡", "😞", "😐", "😊", "😍"];
  // const stars = [1, 2, 3, 4, 5];

  useEffect(() => {
    const fetchCompanyProfile = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://zola.technology/api/company/profile/",
          { headers: { Authorization: `Bearer ${token}` } }
        );

        console.log(response.data);
        const preferredName = response.data.preferred_business_name;

        setCompanyData(response.data);
        setPreferredBusinessName(preferredName);
        const fullCompanyName = response.data.company_name;
        const shortenedName = shortenCompanyName(fullCompanyName);
        setCompanyName(shortenedName);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCompanyProfile();
  }, []);

  const handleUpdateCompanyRating = async () => {
    try {
      const token = localStorage.getItem("token");
      const username = companyData.username;

      // Prepare the payload based on the selected rating
      const ratingPayload = {
        company_rate_style: selection === "emojis" ? "emojis" : "stars", // Adjust based on the selection
      };

      console.log(ratingPayload);
      // Send a PATCH request to update the company with the new rating style
      await axios.patch(
        `https://zola.technology/api/company/update/${username}`,
        ratingPayload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      toast.success("Company rating style updated successfully!");
      console.log("Company rating style updated successfully!");
    } catch (error) {
      toast.error("Error updating company rating style:", error);
      console.error("Error updating company rating style:", error);
    }
  };

  return (
    <div className="flex w-full flex-col lg:flex-row min-h-screen md:p-2  dark:bg-gray-900 transition duration-300">
      <ToastContainer />
      {/* Toggle Selection */}
      <div className="lg:w-1/2 w-full flex flex-col items-center gap-8 rounded-[10px] p-6 bg-white dark:bg-gray-700 transition duration-300 ">
        {/* Title */}
        <h2 className="text-[20px] font-medium text-[#6F707E] dark:text-gray-200">
          Choose your preferred review method
        </h2>

        {/* Emoji and Star Buttons */}
        <div className="flex justify-center items-center gap-2">
          <img
            src={emojiSvg}
            alt="Emoji Image"
            onClick={() => setSelection("emojis")}
            className={`flex flex-col items-center justify-center px-4 py-4 rounded-[16px] border-[1px] border-[#EAEAEC] font-semibold text-[20px] transition-all h-40 bg-white
            ${
              selection === "emojis"
                ? "border-blue-500 text-blue-600 shadow-lg"
                : "border-gray-200 text-gray-600 hover:bg-blue-50 hover:border-blue-400"
            }`}
          />

          <img
            src={starsSvg}
            alt="Star Image"
            onClick={() => setSelection("stars")}
            className={`flex flex-col items-center justify-center px-4 py-4 rounded-[16px] border-[1px] border-[#EAEAEC] font-semibold text-[20px] transition-all h-40 bg-white
            ${
              selection === "stars"
                ? "border-blue-500 text-blue-600 shadow-lg"
                : "border-gray-200 text-gray-600 hover:bg-blue-50 hover:border-blue-400"
            }`}
          />
        </div>

        {/* Update Button */}
        <div className="mt-4">
          <button
            aria-label="Update"
            onClick={handleUpdateCompanyRating}
            className="px-24 py-2 bg-blue-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-blue-700 hover:scale-105 transition-all"
          >
            Update
          </button>
        </div>
      </div>

      {/* Mobile Preview */}
      <div className="lg:w-1/2 relative ">
        <div className="iphone-container">
          <div className="iphone bg-white">
            <div className="buttons">
              <div className="silent"></div>
              <div className="sleep"></div>
              <div className="vol-up"></div>
              <div className="vol-down"></div>
            </div>
            <div className="top">
              {/* <div className="black-bar"></div> */}
              {/* <div className="iphone-top"></div> */}
            </div>
            <div className="components">
              <div className="speaker"></div>
              <div className="camera">
                <div className="shine-left"></div>
                <div className="shine-right"></div>
              </div>
            </div>
            <div className="top-bar"></div>
            <div className="bottom-bar"></div>
            <div className="h-full w-full flex justify-center items-center flex-col">
              {/* Business Info Section */}
              <div className="p-8 mb-8 flex flex-col items-center">
                {/* Profile Image */}
                <div className="relative">
                  {imageLoading && (
                    <div className="w-24 h-24 bg-gray-200 rounded-full mb-4 animate-pulse"></div>
                  )}
                  {companyData.profile_image && (
                    <img
                      src={companyData.profile_image}
                      alt="Company Profile"
                      className={`w-24 h-24 rounded-full mb-4 border-4 border-blue-300 shadow-lg transition-opacity duration-500 ${
                        imageLoading ? "opacity-0" : "opacity-100"
                      }`}
                      onLoad={() => setImageLoading(false)} // Handle image load
                      onError={() => setImageLoading(false)} // Handle image error
                    />
                  )}
                </div>

                {/* Business Name */}
                <h1 className="text-xl text-center md:text-2xl lg:text-3xl font-bold text-gray-800 mb-2">
                  {preferredBusinessName || companyName}
                </h1>
                <p className="text-sm text-gray-500">{companyName}</p>
              </div>

              {/* Rating Section */}
              <h2 className="text-2xl w-3/4 font-bold mb-6 text-gray-900 text-center">
                How was your experience today
              </h2>
              {/* Emoji and Star rendering */}
              <div className="flex justify-center items-center">
                {selection === "emojis" ? (
                  emojis.map((emoji) => (
                    <div
                      key={emoji}
                      className="p-2 text-4xl cursor-pointer transition-all hover:scale-125"
                    >
                      {emoji}
                    </div>
                  ))
                ) : (
                  <div className="flex justify-around gap-4">
                    {[1, 2, 3, 4, 5].map((rating) => (
                      <FaStar
                        key={rating}
                        size={40}
                        className={`cursor-pointer transition-colors duration-300 ${
                          rating <= selectedRating
                            ? "text-yellow-500"
                            : "text-gray-300"
                        }`}
                        onClick={() => setSelectedRating(rating)}
                      />
                    ))}
                  </div>
                )}
              </div>

              <div className="stuff">
                <div className="service">
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                </div>
                <div className="absolute top-[11px] right-[32px] h-[16px] w-[32px] border border-white rounded-[2px] flex items-center z-[200]">
                  <div className="absoulte bg-white top-[4px] right-[-4px] h-[6px] w-[2px] rounded-sm"></div>
                  <div className="absolute bg-black left-[1px] h-[12px] w-[18px] rounded-sm"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmojiStarMobile;
