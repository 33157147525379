import React from 'react';
import { FaUsers, FaExclamationTriangle, FaChartLine } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

function BranchTable({ paginatedBranches }) {
  const navigate = useNavigate();

  // Function to handle redirection when "Details" is clicked
  const handleDetailsClick = (branchId) => {
    // Navigate to the BranchDashboard page with the branch ID
    navigate(`/hqdashboard/branchdashboard/${branchId}`);
    console.log(`/${branchId}`)
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full table-auto border-separate border-spacing-y-3">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left font-normal">S/N</th>
            <th className="px-4 py-2 text-left font-normal">Name</th>
            <th className="px-4 py-2 text-left font-normal">People</th>
            <th className="px-4 py-2 text-left font-normal">Performance</th>
            <th className="px-4 py-2 text-left font-normal">Issues</th>
            <th className="px-4 py-2 text-left font-normal">Feedbacks</th>
            <th className="px-4 py-2 text-left font-normal">Engagement</th>
            {/* <th className="px-4 py-2 text-left font-normal">Rewards</th> */}
            <th className="px-4 py-2 text-left font-normal">Action</th>
          </tr>
        </thead>
        <tbody>
          {paginatedBranches.map((branch, index) => (
            <tr
              key={branch.id}
              className="bg-white text-gray-700 border border-gray-300 rounded-lg shadow-sm hover:shadow-md transition"
            >
              <td data-cell="S/N" className="px-4 py-2">{index + 1}</td>
              <td data-cell="Name" className="px-4 py-2">{branch.name}</td>
              <td data-cell="People" className="px-4 py-2">{branch.employees.toLocaleString()}</td>
              <td data-cell="Performance" className="px-4 py-2">{branch.performance}%</td>
              <td data-cell="Issues" className="px-4 py-2">
                <span className="bg-green-100 text-green-400">{branch.issues}</span>
              </td>
              <td data-cell="Reviews" className="px-4 py-2">{branch.feedbacks}</td>
              <td data-cell="Engagements" className="px-4 py-2">{branch.taps}</td>
              {/* <td data-cell="Rewards" className="px-4 py-2">300</td> */}
              <td data-cell="Action" className="px-4 py-2">
                <button
                  aria-label="Details"
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                  onClick={() => handleDetailsClick(branch.id)} // Handle click
                >
                  Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default BranchTable;
