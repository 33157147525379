import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import axios from "axios";
import { useRating } from "../../context/RatingContext";

const shortenCompanyName = (fullCompanyName) => {
  let mainName = fullCompanyName.split(" - ")[0];
  if (mainName === fullCompanyName) {
    mainName = fullCompanyName.split(",")[0];
  }
  return mainName;
};

const incrementTapCount = async (company_username, employee_username) => {
  try {
    const response = await fetch(
      `https://zola.technology/api/company/employees/update/${company_username}/${employee_username}/increment-taps/`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      console.log("Tap count incremented successfully");
    } else {
      console.error("Failed to increment tap count");
    }
  } catch (error) {
    console.error("Error while sending tap data to backend", error);
  }
};

function EmployeeRating() {
  const { company_username, employee_username } = useParams();
  const { setEmployeeRating } = useRating();
  const [hoverRating, setHoverRating] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [preferredBusinessName, setPreferredBusinessName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [imageLoading, setImageLoading] = useState(true);
  const [employeeName, setEmployeeName] = useState("");
  const [companyRateStyle, setCompanyRateStyle] = useState("");
  const [selectedTeamRating, setSelectedTeamRating] = useState(0); // Team rating
  const [selectedExperienceRating, setSelectedExperienceRating] = useState(0); // Experience rating
  const [hoverExperienceRating, setHoverExperienceRating] = useState(0); // Hover effect for experience rating
  const [placeId, setPlaceId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBusinessName = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/get/${company_username}`
        );
        const preferredName = response.data.preferred_business_name;
        setPreferredBusinessName(preferredName);
        const fullCompanyName = response.data.company_name;
        const shortenedName = shortenCompanyName(fullCompanyName);
        setCompanyName(shortenedName);
        setProfileImage(response.data.profile_image);
        setCompanyRateStyle(response.data.company_rate_style);
      } catch (error) {
        console.error("Error fetching company profile:", error);
      }
    };
    fetchBusinessName();
  }, [company_username]);

  useEffect(() => {
    const getEmployeeName = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/employees/get/${company_username}/${employee_username}/employee-name/`
        );
        setEmployeeName(response.data.name);
      } catch (error) {
        console.error("Error fetching employee profile:", error);
      }
    };
    getEmployeeName();
  }, [company_username, employee_username]);

    // Call this function once both ratings are selected
    useEffect(() => {
      if (selectedTeamRating > 0 && selectedExperienceRating > 0) {
        handleRatingSubmission();
      }
    }, [selectedTeamRating, selectedExperienceRating]);
    
    const fetchPlaceId = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/reviews/${company_username}`
        );
  
        const placeId = response.data;
        setPlaceId(placeId);
        return placeId;
      } catch (error) {
        console.error("Error fetching place ID:", error);
        setPlaceId(null);
        return null;
      }
    };
  
    useEffect(() => {
      fetchPlaceId();
    }, [company_username]);

  const handleRatingSubmission = async () => {
    const averageRating = selectedExperienceRating;
  
    if (averageRating > 3) {
      // Fetch placeId and navigate to Google Reviews
      const placeId = await fetchPlaceId();
      if (placeId) {
        const googleReviewURL = generateGoogleReviewURL(placeId);
        window.location.href = googleReviewURL; // Redirect to Google Reviews
      } else {
        console.error("Place ID not found for Google Reviews.");
      }
    } else {
      // Redirect to custom feedback page
      const emoji = renderEmojiRating(selectedExperienceRating);
      navigate(
        `/feedback/${encodeURIComponent(company_username)}/${encodeURIComponent(employee_username)}/${encodeURIComponent(emoji)}/make-a-zola-review`, {
          state: {
            selectedExperienceRating,
            selectedTeamRating,
          }
        }
      );
    }
  }; 
  
  const generateGoogleReviewURL = (placeId) => {
    return `https://search.google.com/local/writereview?placeid=${placeId}`;
  };

  const handleStarClick = (selectedRating) => {
    setEmployeeRating(selectedRating);
  };

  const handleTeamRatingClick = (rating) => {
    setSelectedTeamRating(rating);
    // Trigger increment only if both ratings are filled
    if (rating > 0 && selectedExperienceRating > 0) {
      incrementTapCount(company_username, employee_username);
    }
  };

  const handleExperienceRatingClick = (rating) => {
    setSelectedExperienceRating(rating);
    // Trigger increment only if both ratings are filled
    if (rating > 0 && selectedTeamRating > 0) {
      incrementTapCount(company_username, employee_username);
    }
  };

  const renderEmojiRating = (rating) => {
    const emojis = ["😡", "😞", "😐", "😊", "😍"];
    return emojis[rating - 1];
  };

  return (
    <>
      <div className="min-h-screen flex flex-col items-center justify-center  p-6">
        {/* Business Info Section */}
        <div className="p-8 mb-8 flex flex-col items-center">
          {/* Profile Image */}
          <div className="relative">
            {imageLoading && (
              <div className="w-24 h-24 bg-gray-200 rounded-full mb-4 animate-pulse"></div>
            )}
            {profileImage && (
              <img
                src={profileImage}
                alt="Company Profile"
                className={`w-24 h-24 rounded-full mb-4 border-4 border-blue-300 shadow-lg transition-opacity duration-500 ${
                  imageLoading ? "opacity-0" : "opacity-100"
                }`}
                onLoad={() => setImageLoading(false)} // Handle image load
                onError={() => setImageLoading(false)} // Handle image error
              />
            )}
          </div>
          {/* Business Name */}
          <h1 className="text-xl text-center md:text-2xl lg:text-3xl font-bold text-gray-800 mb-2">
            {preferredBusinessName || companyName}
          </h1>
          <p className="text-sm text-gray-500">{companyName}</p>
        </div>

        {/* Team Member Rating Section */}
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-900 text-center">
          Please rate your experience with our team member.
        </h2>
        <div className="p-6 max-w-md mx-auto transform transition duration-500 hover:scale-105">
          <div className="flex justify-center gap-3">
            {companyRateStyle === "stars" ? (
              [...Array(5)].map((_, index) => {
                const starValue = index + 1;
                const isSelected = starValue <= selectedTeamRating;
                return (
                  <FaStar
                    key={starValue}
                    size={40}
                    className={`cursor-pointer transition-transform duration-300 ${
                      isSelected
                        ? "text-yellow-500 scale-125"
                        : "text-gray-300 opacity-50"
                    }`}
                    onClick={() => handleTeamRatingClick(starValue)}
                    onMouseEnter={() => setHoverRating(starValue)}
                    onMouseLeave={() => setHoverRating(0)}
                  />
                );
              })
            ) : companyRateStyle === "emojis" ? (
              [...Array(5)].map((_, index) => {
                const emojiValue = index + 1;
                const isSelected = emojiValue === selectedTeamRating;
                return (
                  <span
                    key={emojiValue}
                    className={`cursor-pointer transition-transform duration-300 text-xl ${
                      selectedTeamRating === 0
                        ? "text-gray-700 opacity-100"
                        : isSelected
                        ? "text-yellow-500 scale-125 opacity-100"
                        : "opacity-50"
                    }`}
                    onClick={() => handleTeamRatingClick(emojiValue)}
                    onMouseEnter={() => setHoverRating(emojiValue)}
                    onMouseLeave={() => setHoverRating(0)}
                  >
                    {renderEmojiRating(emojiValue)}
                  </span>
                );
              })
            ) : (
              <p>Rating style not available</p>
            )}
          </div>
        </div>

        {/* Experience Rating Section */}
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-900 text-center">
          How was your experience today?
        </h2>
        <div className="p-6 max-w-md mx-auto transform transition duration-500 hover:scale-105">
          <div className="flex justify-center gap-3">
            {companyRateStyle === "stars" ? (
              [...Array(5)].map((_, index) => {
                const starValue = index + 1;
                const isSelected = starValue <= selectedExperienceRating;
                return (
                  <FaStar
                    key={starValue}
                    size={40}
                    className={`cursor-pointer transition-transform duration-300 ${
                      isSelected
                        ? "text-yellow-500 scale-125"
                        : "text-gray-300 opacity-50"
                    }`}
                    onClick={() => handleExperienceRatingClick(starValue)}
                    onMouseEnter={() => setHoverExperienceRating(starValue)}
                    onMouseLeave={() => setHoverExperienceRating(0)}
                  />
                );
              })
            ) : companyRateStyle === "emojis" ? (
              [...Array(5)].map((_, index) => {
                const emojiValue = index + 1;
                const isSelected = emojiValue === selectedExperienceRating;
                return (
                  <span
                    key={emojiValue}
                    className={`cursor-pointer transition-transform duration-300 text-xl ${
                      selectedExperienceRating === 0
                        ? "text-gray-700 opacity-100"
                        : isSelected
                        ? "text-yellow-500 scale-125 opacity-100"
                        : "opacity-50"
                    }`}
                    onClick={() => handleExperienceRatingClick(emojiValue)}
                    onMouseEnter={() => setHoverExperienceRating(emojiValue)}
                    onMouseLeave={() => setHoverExperienceRating(0)}
                  >
                    {renderEmojiRating(emojiValue)}
                  </span>
                );
              })
            ) : (
              <p>Rating style not available</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeeRating;
