import "../stylings/dashboardpage.css";
import ReviewsAnalytics from "./ReviewsAnalytics";
import ReviewAnalytics2 from "./ReviewAnalytics2";
import BarGraph from "./charts/AreaGraph";
import { Link, useOutletContext } from "react-router-dom";

function DashboardPage() {
  const { isExpired } = useOutletContext();

  console.log("Subscription Status:", isExpired);
  return (
    <>
      <section
        className={`relative ${isExpired ? "blur-sm" : ""}`}
      >
        {/* {<ReviewsAnalytics />} */}
        <ReviewAnalytics2 />
        {/* <BarGraph /> */}
      </section>
      {/* Overlay message if the subscription is expired */}
      {isExpired && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-80 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-8 text-center shadow-lg max-w-md">
            <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
              Subscription Expired
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              Your subscription has expired. Please renew to regain full access
              to the dashboard.
            </p>
            <Link to="/dashboard/setting/billing">
              <button aria-label="Go to Billing" className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 transition-colors">
                Go to Billing
              </button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default DashboardPage;
