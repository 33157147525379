import { fontSize } from "@mui/system";
import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";

const PerformanceDashboard = () => {
  const [filter, setFilter] = useState("year");
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "Taps",
        data: [],
        backgroundColor: "rgba(66, 100, 223, 0.4)",
        borderRadius: 4,
        barPercentage: 0.5,
        hoverBackgroundColor: "rgba(66, 100, 223, 1)",
      },
    ],
  });
  const [averageTaps, setAverageTaps] = useState(0);
  const [isMobileScreen, setIsMobileScreen] = useState(window.matchMedia("(max-width: 768px)").matches);
  const [isTabletScreen, setIsTabletScreen] = useState(window.matchMedia("(max-width: 1024px)").matches);

  // Helper function to get month names
  const getMonthNames = () => [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Helper function to get days of the current week
  const getDaysOfWeek = () => {
    const today = new Date();
    const dayIndex = today.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
    const mondayOffset = dayIndex === 0 ? -6 : 1 - dayIndex; // Adjust for Monday start (Sunday wraps around)
    const startOfWeek = new Date(today.setDate(today.getDate() + mondayOffset));
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + i);
      days.push(day.toLocaleDateString("en-US", { weekday: "long" })); // e.g., "Monday"
    }
    return days;
  };

  // Helper function to get weeks in the current month
  const getWeeksInMonth = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const weeks = [];
    let date = new Date(currentYear, currentMonth, 1);

    while (date.getMonth() === currentMonth) {
      const weekStart = new Date(date);
      const weekEnd = new Date(date);
      weekEnd.setDate(weekStart.getDate() + 6);

      weeks.push(
        `Week ${Math.ceil(date.getDate() / 7)} (${weekStart.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
        })} - ${weekEnd.toLocaleDateString("en-US", { month: "short", day: "numeric" })})`
      );

      date.setDate(date.getDate() + 7);
    }

    return weeks;
  };

  const generateLabels = (filter) => {
    if (filter === "year") {
      return getMonthNames();
    } else if (filter === "month") {
      return getWeeksInMonth();
    } else if (filter === "week") {
      return getDaysOfWeek();
    }
    return [];
  };
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          `https://zola.technology/api/company/employees/performance/get-spread-taps/?filter=${filter}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const result = await response.json();
          const labels = generateLabels(filter);
          const tapsData = result.periods.map((period) => period.taps);

          setData({
            labels,
            datasets: [
              {
                label: "Taps",
                data: tapsData,
                backgroundColor: "rgba(66, 100, 223, 0.4)",
                borderRadius: 4,
                barPercentage: 0.5,
                hoverBackgroundColor: "rgba(66, 100, 223, 1)",
              },
            ],
          });

          // Calculate average taps
          const totalTaps = tapsData.reduce((sum, taps) => sum + taps, 0);
          setAverageTaps(result.total_filtered_taps);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const handleResize = () => {
      setIsMobileScreen(window.matchMedia("(max-width: 768px)").matches);
      setIsTabletScreen(window.matchMedia("(max-width: 1024px)").matches);
    };

    window.addEventListener("resize", handleResize);
    fetchData();
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [filter]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.raw} taps`,
        },
        backgroundColor: "#ffffff",
        titleColor: "#333",
        bodyColor: "#333",
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
        titleFont: { weight: "bold" },
      },
    },
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          color: "#666",
          font: { size: 12 },
          maxRotation: 0,
          minRotation: 0,
       
        },
    
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: Math.ceil(Math.max(...data.datasets[0].data) / 5) || 1,
          color: "#999",
          font: { size: 12 },
          padding: 10,
        },
        grid: { color: "rgba(0, 0, 0, 0.05)" },
      },
    },
  };
  console.log("this is data", averageTaps)

  return (
    <div className="flex flex-col lg:flex-row lg:items-start md:w-full xs:w-full space-y-4 lg:space-y-0 lg:space-x-4  rounded-[12px] bg-white h-[400px]">
      <div className="flex flex-col lg:flex-row lg:items-start w-full">
        <div className="w-full mb-3 lg:mb-0">
          <div className="flex justify-between py-2 px-4 border-b border-[#EAEAEC]">
            <div>
            <h3 className=" text-[20px] font-semibold text-[#4C4C4C] dark:text-gray-200">
              Total Taps
            </h3>
            <span className="text-[12px] font-medium text-[#9C9DA6] ">{averageTaps} total {averageTaps>1 ? "taps" : "tap"} based on this {filter}'s data.</span>

            </div>
            <div className="flex items-center border border-[#EAEAEC] rounded-[5px] px-2 h-[30px] mt-3">
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="p-1 dark:bg-gray-700 font-semibold text-[#282A3E] bg-white dark:text-white focus:outline-none"
              >
                <option value="week">This Week</option>
                <option value="month">This Month</option>
                <option value="year">This Year</option>
              </select>
            </div>
          </div>
     
          <div className="w-full px-4 py-4">
            <Bar
              data={data}
              options={options}
              style={{
                width: isMobileScreen ? "100%" : isTabletScreen ? "100%" : "100%",
                height: isMobileScreen ? "200px" : isTabletScreen ? "250px" : "350px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceDashboard;
