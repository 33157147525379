import { useEffect, useState } from "react";

const CompanyProfile = () => {
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    company_name: "",
    phone_number: "",
    email: "",
    place_id: "",
    profile_image: "",
    preferred_business_name: "",
  });
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          "https://zola.technology/api/company/profile/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        setData(result);
      } catch (error) {
        setErrors((prevErrors) => ({ ...prevErrors, fetch: error.message }));
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        "https://zola.technology/api/company/update/",
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setErrors(errorData);
        setIsSubmitting(false);
        return;
      }

      setMessage("Profile updated successfully!");
      setErrors({});
    } catch (error) {
      setErrors((prevErrors) => ({ ...prevErrors, submit: error.message }));
      setMessage("");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validate file size (max 2MB)
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
    if (file.size > maxSizeInBytes) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        upload: "File size should not exceed 2MB.",
      }));
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    setUploadingImage(true);
    const token = localStorage.getItem("token");

    try {
      const response = await fetch("https://zola.technology/api/upload-file/", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      const result = await response.json();
      setData((prevData) => ({
        ...prevData,
        profile_image: result.file_url,
      }));
      setMessage("Image uploaded successfully!");
      setErrors({});
    } catch (error) {
      setErrors((prevErrors) => ({ ...prevErrors, upload: error.message }));
    } finally {
      setUploadingImage(false);
    }
  };

  const renderInputField = ({
    label,
    name,
    type = "text",
    readOnly = false,
  }) => (
    <div key={name} className="form-group">
      <label className="block text-black dark:text-white mb-1">{label}:</label>
      <input
        type={type}
        name={name}
        value={data[name] || ""}
        onChange={handleChange}
        readOnly={readOnly}
        className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-100 dark:bg-gray-800 text-black dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      {errors[name] && (
        <div className="text-red-500 dark:text-red-300 mt-1 text-sm">
          {errors[name]}
        </div>
      )}
    </div>
  );

  if (errors.fetch) {
    return (
      <div className="text-red-500 dark:text-red-300">
        Error: {errors.fetch}
      </div>
    );
  }

  if (!data) {
    return (
      <div className="mt-28 ml-28 text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-black dark:text-white mb-4">
        Company Profile
      </h2>

      <div className="flex justify-center mb-6">
        <img
          src={data.profile_image || "https://via.placeholder.com/150"}
          alt="Profile"
          className={`w-32 h-32 rounded-full border border-gray-300 dark:border-gray-600 ${
            uploadingImage ? "opacity-50" : ""
          }`}
        />
      </div>

      <form className="space-y-6" onSubmit={handleSubmit}>
        {message && (
          <div className="text-green-500 dark:text-green-300 bg-green-100 dark:bg-green-800 p-4 rounded-md">
            {message}
          </div>
        )}
        {errors.submit && (
          <div className="text-red-500 dark:text-red-300 bg-red-100 dark:bg-red-800 p-4 rounded-md">
            {errors.submit}
          </div>
        )}
        {errors.upload && (
          <div className="text-red-500 dark:text-red-300 bg-red-100 dark:bg-red-800 p-4 rounded-md">
            {errors.upload}
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {[
            { label: "First Name", name: "first_name" },
            { label: "Last Name", name: "last_name" },
            { label: "Username", name: "username" },
            { label: "Company Name", name: "company_name" },
            { label: "Phone Number", name: "phone_number" },
            { label: "Email", name: "email" },
            { label: "Place ID", name: "place_id", readOnly: true },
            {
              label: "Preferred Business Name",
              name: "preferred_business_name",
            },
          ].map(renderInputField)}

          <div className="form-group">
            <label className="block text-black dark:text-white mb-1">
              Upload Profile Image:
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-100 dark:bg-gray-800 text-black dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <button
          type="submit"
          className={`w-full bg-blue-600 text-white py-3 rounded-md font-semibold ${
            isSubmitting ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
          }`}
          disabled={isSubmitting}
          aria-live={isSubmitting ? "assertive" : "off"}
          aria-busy={isSubmitting ? "true" : "false"}
          aria-label={
            isSubmitting ? "Profile update in progress" : "Update Profile"
          }
        >
          {isSubmitting ? "Updating..." : "Update Profile"}
        </button>
      </form>

      {uploadingImage && (
        <div className="text-blue-500 mt-4">Uploading image...</div>
      )}
    </div>
  );
};

export default CompanyProfile;
