import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const EditForm = ({
  selectedItem = { name: "", username: "" },
  onClose,
  onUpdate,
}) => {
  const [formValues, setFormValues] = useState({
    name: selectedItem.name,
    username: selectedItem.username,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedItem && (selectedItem.name || selectedItem.username)) {
      setFormValues({
        name: selectedItem.name || "",
        username: selectedItem.username || "",
      });
    }
  }, [selectedItem]);

  const handleFormChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
    // Clear errors for the field being updated
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formValues.name.trim()) {
      newErrors.name = "Full name is required.";
    }
    if (formValues.username.length < 6) {
      newErrors.username = "Username must be at least 6 characters long.";
    }
    return newErrors;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      await onUpdate(formValues);
      onClose();
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-xl  w-[90%] max-w-sm space-y-4"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
      >
        <h2 className="text-lg rounded-t-xl p-4 font-semibold text-white bg-[#0C4EF8] dark:text-white mb-4">
          Edit Item
        </h2>
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4 px-6">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-[#282A3E] dark:text-gray-300"
            >
              Full Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleFormChange}
              className="mt-1 block w-full px-3 py-2 border border-[#EAEAEC] bg-white dark:border-gray-600 rounded-md dark:bg-gray-800 text-black dark:text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
              required
            />
            {errors.name && (
              <p className="text-sm text-red-600 mt-1">{errors.name}</p>
            )}
          </div>
          <div className="mb-4 px-6">
            <label
              htmlFor="username"
              className="block text-sm font-medium text-[#282A3E] dark:text-gray-300"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={formValues.username}
              onChange={handleFormChange}
              className="mt-1 block w-full px-3 py-2 border border-[#EAEAEC] bg-white dark:border-gray-600 rounded-md dark:bg-gray-800 text-black dark:text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
              required
            />
            {errors.username && (
              <p className="text-sm text-red-600 mt-1">{errors.username}</p>
            )}
          </div>
          <div className="flex p-6">
            <button
              aria-label="Update"
              type="submit"
              className="px-4 py-2 bg-[#0C4EF8] w-full dark:bg-blue-500 text-white text-[16px] font-bold rounded-lg hover:bg-blue-700 dark:hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
              style={{
                boxShadow:
                  "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
              }}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

EditForm.propTypes = {
  selectedItem: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default EditForm;
