import { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaRegMoon,
  FaSun,
  FaSignOutAlt,
  FaCog,
  FaChartBar,
  FaCubes,
  FaBars, // Icon for opening the sidebar
  FaTimes, // Icon for closing the sidebar
} from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import Logo from "../../HQDashboard/components/sidebar/zolaDark.png";
import ZolaLogo from "../../HQDashboard/components/sidebar/Layer_1.png";
import { PiChatCircleDotsFill } from "react-icons/pi";
import signOut from "../../HQDashboard/components/sidebar/SignOut.png";
import { PiChartLineFill } from "react-icons/pi";
import { FaCircleCheck } from "react-icons/fa6";
import { PiCurrencyCircleDollarFill } from "react-icons/pi";
import { PiDeviceMobileCameraFill } from "react-icons/pi";
import { PiUsersThreeFill } from "react-icons/pi";
// import ZolaLogo from "./Layer_1.png";
import { AuthContext } from "../../context/AuthContext";
import { PiTextColumnsFill } from "react-icons/pi";
import { PiChartLineUp } from "react-icons/pi";
import { PiGitBranchBold } from "react-icons/pi";
import { PiSignOut } from "react-icons/pi";
import { MdAddToPhotos } from "react-icons/md";
import { AiFillMessage } from "react-icons/ai";
import { RiSurveyFill } from "react-icons/ri";
import { PiMoneyWavyFill } from "react-icons/pi";
import { HiViewGridAdd } from "react-icons/hi";

const Sidebar = ({ theme, toggleTheme, toggleSidebar, onLogoutRequest }) => {
  const location = useLocation();
  const { currentUser, logout } = useContext(AuthContext);

  const [sidebarVisible, setSidebarVisible] = useState(false); // State to control sidebar visibility

  const handleLogout = () => {
    logout();
  };

  if (!currentUser) {
    return null;
  }

  const userData = JSON.parse(localStorage.getItem("userData"));
  const companyType = userData.company_type;
  const superUser = userData.is_superuser;
  console.log(superUser);

  const isActive = (path) =>
    location.pathname === path
      ? "bg-blue-600 dark:bg-blue-700 text-white"
      : theme === "light"
      ? "text-gray-900 hover:bg-blue-100"
      : "text-white hover:bg-gray-600";

  // Close sidebar when a link is clicked on smaller screens
  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      toggleSidebar();
    }
  };

  return (
    <>
      {/* Button to toggle sidebar */}
      {/* {!sidebarVisible && (
        <button
          className="md:hidden absolute w-[40px] h-[40px] top-6 right-2 z-50 text-gray-700 dark:text-white mr-5 bg-[#FFFFFF] rounded-[4px]"
          onClick={() => setSidebarVisible(true)}
        >
          <FaBars size={24} className="m-2" />
        </button>
      )} */}
      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 transition-all duration-300   ${
          theme === "light"
            ? "bg-white text-gray-900"
            : "bg-gray-900 text-white"
        } w-64 transform
        } md:translate-x-0 overflow-y-auto custom-scrollbar border-r-[1px] border-[#EAEAEC]`} // Added responsive toggle for sidebar visibility
      >
        {/* Top Section: Logo */}
        <div className="p-8">
          <img
            src={theme === "light" ? ZolaLogo : Logo}
            alt="Zola Logo"
            className="w-[150px] h-[30px] object-contain"
          />
        </div>

        {/* Navigation Links */}
        <nav className="flex-1 p-4">
          <ul className="space-y-2 ">
            <li onClick={handleLinkClick}>
              <Link
                to="/dashboard"
                className={`flex items-center gap-4 p-3 rounded-md group ${isActive(
                  "/dashboard"
                )}`}
              >
                <PiTextColumnsFill
                  size={24}
                  className={
                    location.pathname === "/dashboard"
                      ? "text-white"
                      : "text-[#9C9DA6]"
                  }
                />
                <span
                  className={
                    location.pathname === "/dashboard"
                      ? "text-white"
                      : "text-[#9C9DA6]"
                  }
                >
                  Dashboard
                </span>
              </Link>
            </li>

            <li onClick={handleLinkClick}>
              <Link
                to="/dashboard/feedback"
                className={`flex items-center gap-4 p-3 rounded-md group ${isActive(
                  "/dashboard/feedback"
                )}`}
              >
                <PiChatCircleDotsFill
                  size={24}
                  className={
                    location.pathname === "/dashboard/feedback"
                      ? "text-white"
                      : "text-[#9C9DA6]"
                  }
                />
                <span
                  className={
                    location.pathname === "/dashboard/feedback"
                      ? "text-white"
                      : "text-[#9C9DA6]"
                  }
                >
                  Feedback
                </span>
              </Link>
            </li>

            <li onClick={handleLinkClick}>
              <Link
                to="/dashboard/analytics"
                className={`flex items-center gap-4 p-3 rounded-md group ${isActive(
                  "/dashboard/analytics"
                )}`}
              >
                <PiChartLineFill
                  size={24}
                  className={
                    location.pathname === "/dashboard/analytics"
                      ? "text-white"
                      : "text-[#9C9DA6]"
                  }
                />
                <span
                  className={
                    location.pathname === "/dashboard/analytics"
                      ? "text-white"
                      : "text-[#9C9DA6]"
                  }
                >
                  Analytics
                </span>
              </Link>
            </li>

            <li onClick={handleLinkClick}>
              <Link
                to="/dashboard/team-performance"
                className={`flex items-center gap-4 p-3 rounded-md group ${isActive(
                  "/dashboard/team-performance"
                )}`}
              >
                <FaCircleCheck
                  size={24}
                  className={
                    location.pathname === "/dashboard/team-performance"
                      ? "text-white"
                      : "text-[#9C9DA6]"
                  }
                />
                <span
                  className={
                    location.pathname === "/dashboard/team-performance"
                      ? "text-white"
                      : "text-[#9C9DA6]"
                  }
                >
                  Perfomance
                </span>
              </Link>
            </li>

            

            <li onClick={handleLinkClick}>
              <Link
                to="/dashboard/mobile-preview"
                className={`flex items-center gap-4 p-3 rounded-md group ${isActive(
                  "/dashboard/mobile-preview"
                )}`}
              >
                <PiDeviceMobileCameraFill
                  size={24}
                  className={
                    location.pathname === "/dashboard/mobile-preview"
                      ? "text-white"
                      : "text-[#9C9DA6]"
                  }
                />
                <span
                  className={
                    location.pathname === "/dashboard/mobile-preview"
                      ? "text-white"
                      : "text-[#9C9DA6]"
                  }
                >
                  Mobile preview
                </span>
              </Link>
            </li>

            {superUser ? (
              <li onClick={handleLinkClick}>
                <Link
                  to="/dashboard/create-account"
                  className={`flex items-center gap-4 p-3 rounded-md group ${isActive(
                    "/dashboard/create-account"
                  )}`}
                >
                  <MdAddToPhotos
                    size={24}
                    className={
                      location.pathname === "/dashboard/create-account"
                        ? "text-white"
                        : "text-[#9C9DA6]"
                    }
                  />
                  <span
                    className={
                      location.pathname === "/dashboard/create-account"
                        ? "text-white"
                        : "text-[#9C9DA6]"
                    }
                  >
                    New company
                  </span>
                </Link>
              </li>
            ) : (
              ""
            )}

            {superUser ? (
              <li onClick={handleLinkClick}>
                <Link
                  to="/dashboard/branch"
                  className={`flex items-center gap-4 p-3 rounded-md group ${isActive(
                    "/dashboard/branch"
                  )}`}
                >
                  <MdAddToPhotos
                    size={24}
                    className={
                      location.pathname === "/dashboard/branch"
                        ? "text-white"
                        : "text-[#9C9DA6]"
                    }
                  />
                  <span
                    className={
                      location.pathname === "/dashboard/branch"
                        ? "text-white"
                        : "text-[#9C9DA6]"
                    }
                  >
                    Branches
                  </span>
                </Link>
              </li>
            ) : (
              ""
            )}
              {superUser ? (
              <li onClick={() => handleLinkClick("payment")} >
                <Link
                  to={{
                    pathname: "/dashboard/payment",
                    state: {transactionType: "payment"},
                  }}
                  className={`flex items-center gap-4 p-3 rounded-md group ${isActive(
                    "/dashboard/payment"
                  )}`}
                >
                  <PiMoneyWavyFill
                    size={24}
                    className={
                      location.pathname === "/dashboard/payment"
                        ? "text-white"
                        : "text-[#9C9DA6]"
                    }
                  />
                  <span
                    className={
                      location.pathname === "/dashboard/payment"
                        ? "text-white"
                        : "text-[#9C9DA6]"
                    }
                  >
                    Payment
                  </span>
                </Link>
              </li>
            ) : (
              ""
            )}
             {superUser ? (
              <li onClick={() => handleLinkClick("subscription")}>
                <Link
                  to={{
                    pathname: "/dashboard/subscriptions",
                  state: {transactionType: "subscription"},
                }}
                  className={`flex items-center gap-4 p-3 rounded-md group ${isActive(
                    "/dashboard/subscriptions"
                  )}`}
                >
                  <HiViewGridAdd
                    size={24}
                    className={
                      location.pathname === "/dashboard/subscriptions"
                        ? "text-white"
                        : "text-[#9C9DA6]"
                    }
                  />
                  <span
                    className={
                      location.pathname === "/dashboard/subscriptions"
                        ? "text-white"
                        : "text-[#9C9DA6]"
                    }
                  >
                    Subscriptions
                  </span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {companyType === "team" ? (
              <li onClick={handleLinkClick}>
                <Link
                  to="/dashboard/companyemployees"
                  className={`flex items-center gap-4 p-3 rounded-md group ${isActive(
                    "/dashboard/companyemployees"
                  )}`}
                >
                  <PiGitBranchBold
                    size={24}
                    className={
                      location.pathname === "/dashboard/companyemployees"
                        ? "text-white"
                        : "text-[#9C9DA6]"
                    }
                  />
                  <span
                    className={
                      location.pathname === "/dashboard/companyemployees"
                        ? "text-white"
                        : "text-[#9C9DA6]"
                    }
                  >
                    Employee
                  </span>
                </Link>
              </li>
            ) : (
              ""
            )}

            {/* <li onClick={handleLinkClick}>
              <Link
                to="/dashboard/survey"
                className={`flex items-center gap-4 p-3 rounded-md group ${isActive(
                  "/dashboard/survey"
                )}`}
              >
                <RiSurveyFill
                  size={24}
                  className={
                    location.pathname === "/dashboard/survey"
                      ? "text-white"
                      : "text-[#9C9DA6]"
                  }
                />
                <span
                  className={
                    location.pathname === "/dashboard/survey"
                      ? "text-white"
                      : "text-[#9C9DA6]"
                  }
                >
                  Survey
                </span>
              </Link>
            </li> */}

            <li onClick={handleLinkClick}>
              <Link
                to="/dashboard/setting"
                className={`flex items-center gap-4 p-3 rounded-md group ${isActive(
                  "/dashboard/setting"
                )}`}
              >
                <FaCog
                  size={24}
                  className={
                    location.pathname === "/dashboard/settings"
                      ? "text-white"
                      : "text-[#9C9DA6]"
                  }
                />
                <span
                  className={
                    location.pathname === "/dashboard/settings"
                      ? "text-white"
                      : "text-[#9C9DA6]"
                  }
                >
                  Settings
                </span>
              </Link>
            </li>
          </ul>
        </nav>

        {/* Bottom Section: Dark Mode Toggle & Logout */}
        <div className="p-4 flex my-[50px] flex-col items-center space-y-4">
          {/* <div className="bg-[#F4F4F4] rounded-full py-2  shadow-inner w-[80px] ">
            <label
              htmlFor="theme-toggle"
              className="relative  flex items-center cursor-pointer w-full"
            >
              <input
                id="theme-toggle"
                type="checkbox"
                checked={theme === "dark"}
                onChange={toggleTheme}
                className="sr-only"
              />
              <div className=" flex justify-between items-center  space-x-2 ml-2">
                <div
                  className={`w-6 h-6 rounded-full transition-all ${
                    theme === "dark" ? "bg-white shadow-lg" : ""
                  } flex items-center justify-center  `}
                >
                  <FaRegMoon
                    className={
                      theme === "dark" ? "text-gray-500" : "text-gray-500"
                    }
                    size={12}
                  />
                </div>
                <div
                  className={`w-6 h-6 rounded-full transition-all ${
                    theme === "light" ? "bg-white shadow-lg" : ""
                  } flex items-center justify-center`}
                >
                  <FaSun
                    className={
                      theme === "light" ? "text-gray-500" : "text-gray-500"
                    }
                    size={12}
                  />
                </div>
              </div>
            </label>
          </div> */}
          {/* Bottom Section: Dark Mode Toggle & Logout */}
          <div className="p-4 flex my-[50px] flex-col items-center space-y-4">
            <button
              aria-label="Logout"
              onClick={onLogoutRequest}
              className="flex items-center gap-2 p-3 px-[20px] border border-[#EAEAEC] text-red-500 rounded-[8px] hover:bg-red-100"
            >
              <PiSignOut size={20} />
              <span className="text-[16px]">Logout</span>
            </button>
          </div>
        </div>
      </div>
      {/* Overlay to close the sidebar when visible */}
      {/* {sidebarVisible && (
        <div
          className="fixed inset-0 bg-black opacity-50 md:hidden z-40"
          onClick={() => setSidebarVisible(false)} // Close the sidebar when overlay is clicked
        ></div>
      )} */}
    
      <style jsx>{`
        .custom-scrollbar::-webkit-scrollbar {
          width: 0; /* Remove scrollbar space */
          background: transparent; /* Optional: just make scrollbar invisible */
        }
        .custom-scrollbar {
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none; /* Internet Explorer 10+ */
        }
      `}</style>
    </>
  );
};

export default Sidebar;
